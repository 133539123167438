// src/pages/Account.jsx

import React, { useEffect, useState } from "react";
import api from "../services/api";

function Account() {
  const [planType, setPlanType] = useState("");
  const [responsesUsed, setResponsesUsed] = useState(0);
  const [limit, setLimit] = useState(null);
  const [brandName, setBrandName] = useState("");
  const brandId = localStorage.getItem("brandId");
  const role = localStorage.getItem("role");

  const fetchData = async () => {
    if (!brandId) return;
    try {
      // Obtenemos info de la marca
      const respClients = await api.get(`/clients`);
      const allClients = respClients.data;
      // Buscamos la que coincida con brandId
      const foundClient = allClients.find((cl) => cl.id === parseInt(brandId));
      if (!foundClient) return;

      setBrandName(foundClient.brand_name);

      // admin es un array de admin records
      const adminRecord = foundClient.admins && foundClient.admins[0];
      if (adminRecord) {
        setPlanType(adminRecord.plan_type);
      } else {
        setPlanType("free");
      }

      // responses_count
      const used = foundClient.responses_count || 0;
      setResponsesUsed(used);

      // Definimos el límite de acuerdo al plan
      if (adminRecord && adminRecord.plan_type === "free") {
        setLimit(10);
      } else if (adminRecord && adminRecord.plan_type === "platinium") {
        setLimit(20);
      } else {
        // Premium/pending => sin límite
        setLimit(null);
      }
    } catch (error) {
      console.log("Error al obtener datos de la cuenta:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Mi Cuenta</h2>

      {!brandId ? (
        <div className="p-4 bg-yellow-100 border border-yellow-300 text-yellow-800 rounded">
          No tienes ninguna marca asociada. Si eres vendedor, selecciona un
          cliente; si eres administrador, tu brandId podría no haberse guardado.
        </div>
      ) : (
        <div className="bg-white rounded-md shadow p-4">
          <p className="text-gray-700 mb-2">
            <strong>Marca: </strong> {brandName}
          </p>
          <p className="text-gray-700 mb-2">
            <strong>Plan Actual: </strong>
            {planType === "pending" ? "Pendiente (premium/platinium)" : planType}
          </p>

          {limit === null ? (
            <p className="text-gray-700">
              <strong>Respuestas: </strong>Ilimitadas (Plan Premium o Pending)
            </p>
          ) : (
            <p className="text-gray-700">
              <strong>Respuestas usadas: </strong> {responsesUsed} / {limit}
            </p>
          )}

          {role === "vendedor" ? (
            <p className="text-sm text-gray-500 mt-4">
              (Eres vendedor. Para cambiar de plan, ve a la lista de clientes.)
            </p>
          ) : (
            <p className="text-sm text-gray-500 mt-4">
              (Si necesitas cambiar de plan, contacta al vendedor.)
            </p>
          )}
        </div>
      )}
    </div>
  );
}

export default Account;
