// src/pages/Questions.jsx
import React, { useState, useEffect } from "react";
import api from "../services/api";
import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";

function Questions() {
  const [questions, setQuestions] = useState([]);
  const [text, setText] = useState("");
  const [type, setType] = useState("satisfaction");
  // En vez de usar "options" como un string con comas, pasamos a un array.
  const [optionList, setOptionList] = useState([]);
  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  // Removemos la casilla de is_shared en la UI, pero lo seteamos internamente a true.
  const [emojis, setEmojis] = useState("");
  const [editingQuestion, setEditingQuestion] = useState(null);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await api.get("/questions/");
      setQuestions(response.data);
    } catch (error) {
      console.error("Error al obtener preguntas:", error);
    }
  };

  const resetForm = () => {
    setEditingQuestion(null);
    setText("");
    setType("satisfaction");
    setOptionList([]);
    setMinValue("");
    setMaxValue("");
    setEmojis("");
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Manejo de "optionList" dinámico
  // ─────────────────────────────────────────────────────────────────────────────
  const addOptionItem = () => {
    setOptionList([...optionList, ""]);
  };

  const updateOptionItem = (idx, value) => {
    const newList = [...optionList];
    newList[idx] = value;
    setOptionList(newList);
  };

  const removeOptionItem = (idx) => {
    const newList = [...optionList];
    newList.splice(idx, 1);
    setOptionList(newList);
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Creación de nueva pregunta
  // ─────────────────────────────────────────────────────────────────────────────
  const handleCreateQuestion = async (e) => {
    e.preventDefault();
    try {
      // Forzamos is_shared = true
      const isSharedAlwaysTrue = true;

      // Para emojis, construimos array si aplica
      let emojisArray = null;
      if (type === "satisfaction" && emojis) {
        emojisArray = emojis.split(",").map((em) => em.trim()).filter(Boolean);
      }

      // Para opciones, tomamos el array "optionList" si type multiple|unique
      let finalOptions = null;
      if ((type === "multiple" || type === "unique") && optionList.length) {
        finalOptions = optionList;
      }

      const payload = {
        text,
        type,
        is_shared: isSharedAlwaysTrue,
        options: (type === "multiple" || type === "unique") ? finalOptions : null,
        min_value: type === "scale" ? parseInt(minValue) : null,
        max_value: type === "scale" ? parseInt(maxValue) : null,
        emojis: type === "satisfaction" ? emojisArray : null,
      };

      await api.post("/questions/", payload);
      fetchQuestions();
      resetForm();
    } catch (error) {
      console.error("Error al crear pregunta:", error);
      alert("Error al crear la pregunta");
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Edición de pregunta
  // ─────────────────────────────────────────────────────────────────────────────
  const handleEditQuestion = (question) => {
    setEditingQuestion(question);
    setText(question.text);
    setType(question.type);

    // Reconstruimos optionList
    if (question.options && (question.type === "multiple" || question.type === "unique")) {
      setOptionList(question.options);
    } else {
      setOptionList([]);
    }

    setMinValue(question.min_value?.toString() || "");
    setMaxValue(question.max_value?.toString() || "");

    if (question.emojis && Array.isArray(question.emojis)) {
      setEmojis(question.emojis.join(", "));
    } else {
      setEmojis("");
    }
  };

  const handleUpdateQuestion = async (e) => {
    e.preventDefault();
    try {
      // Forzamos is_shared = true
      const isSharedAlwaysTrue = true;

      let emojisArray = null;
      if (type === "satisfaction" && emojis) {
        emojisArray = emojis.split(",").map((em) => em.trim()).filter(Boolean);
      }

      let finalOptions = null;
      if ((type === "multiple" || type === "unique") && optionList.length) {
        finalOptions = optionList;
      }

      const payload = {
        text,
        type,
        is_shared: isSharedAlwaysTrue,
        options: (type === "multiple" || type === "unique") ? finalOptions : null,
        min_value: type === "scale" ? parseInt(minValue) : null,
        max_value: type === "scale" ? parseInt(maxValue) : null,
        emojis: type === "satisfaction" ? emojisArray : null,
      };

      await api.put(`/questions/${editingQuestion.id}`, payload);
      fetchQuestions();
      resetForm();
    } catch (error) {
      console.error("Error actualizando pregunta:", error);
      if (error.response && error.response.data && error.response.data.detail) {
        alert(error.response.data.detail);
      } else {
        alert("Error al actualizar la pregunta");
      }
    }
  };

  // ─────────────────────────────────────────────────────────────────────────────
  // Eliminación de pregunta
  // ─────────────────────────────────────────────────────────────────────────────
  const handleDeleteQuestion = async (questionId) => {
    if (
      window.confirm(
        "¿Estás seguro de eliminar esta pregunta? Esta acción es irreversible."
      )
    ) {
      try {
        await api.delete(`/questions/${questionId}`);
        fetchQuestions();
        alert("Pregunta eliminada correctamente");
      } catch (error) {
        alert(
          error.response?.data?.detail || "Error al eliminar la pregunta"
        );
      }
    }
  };

  return (
    <div className="min-h-screen pt-16 px-4 bg-gray-50">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          Administración de Preguntas
        </h2>

        {/* Mensaje adicional de cuidado con ortografía */}
        <div className="mb-4 p-3 bg-blue-50 border border-blue-200 text-sm text-blue-800 rounded-md">
          <strong>¡Atención!</strong> Asegúrate de que la ortografía y sintaxis
          de la pregunta sea correcta. Una vez haya respuestas registradas,
          <span className="font-semibold"> no podrás editar</span> esta pregunta.
        </div>

        <form
          onSubmit={editingQuestion ? handleUpdateQuestion : handleCreateQuestion}
          className="space-y-4"
        >
          <div>
            <input
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="¿Qué te pareció nuestro servicio?"
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-[#FF3008]"
              required
            />
            <p className="text-xs text-gray-500 mt-1">
              Escribe aquí la pregunta de forma clara y cuidadosa.
            </p>
          </div>

          <select
            value={type}
            onChange={(e) => {
              setType(e.target.value);
              setOptionList([]); // Reiniciamos la lista si cambia el tipo
            }}
            className="w-full p-2 border border-gray-300 rounded-md bg-white"
          >
            <option value="satisfaction">Satisfacción (Emojis)</option>
            <option value="multiple">Múltiple Opción</option>
            <option value="unique">Opción Única</option>
            <option value="open">Respuesta Abierta</option>
            <option value="scale">Escala Numérica</option>
          </select>

          {/* Bloque para multiple | unique: se ingresan opciones en array */}
          {(type === "multiple" || type === "unique") && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Opciones
              </label>
              <p className="text-xs text-gray-500 mb-2">
                Ingresa cada opción por separado:
              </p>
              {optionList.map((opt, idx) => (
                <div key={idx} className="flex gap-2 mb-2">
                  <input
                    type="text"
                    value={opt}
                    onChange={(e) => updateOptionItem(idx, e.target.value)}
                    className="flex-1 p-2 border border-gray-300 rounded-md"
                  />
                  <button
                    type="button"
                    onClick={() => removeOptionItem(idx)}
                    className="px-2 bg-red-500 text-white rounded hover:bg-red-600"
                  >
                    X
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={addOptionItem}
                className="px-3 py-1 bg-[#FF3008] text-white rounded text-sm"
              >
                + Agregar Opción
              </button>
            </div>
          )}

          {/* Bloque para Escala */}
          {type === "scale" && (
            <div className="grid grid-cols-2 gap-4">
              <input
                type="number"
                value={minValue}
                onChange={(e) => setMinValue(e.target.value)}
                placeholder="Valor mínimo"
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <input
                type="number"
                value={maxValue}
                onChange={(e) => setMaxValue(e.target.value)}
                placeholder="Valor máximo"
                className="w-full p-2 border border-gray-300 rounded-md"
              />
            </div>
          )}

          {/* Bloque para Satisfacción (caritas/emojis) */}
          {type === "satisfaction" && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Emojis (separados por coma)
              </label>
              <input
                type="text"
                value={emojis}
                onChange={(e) => setEmojis(e.target.value)}
                placeholder="Ej: 😠, 😞, 😐, 😊, 😍"
                className="w-full p-2 border border-gray-300 rounded-md"
              />
              <p className="text-sm text-gray-500 mt-1">
                Si no se especifican, el sistema usará caritas por defecto.
              </p>
            </div>
          )}

          <button
            type="submit"
            className="w-full py-2 px-4 bg-[#FF3008] text-white rounded-md hover:bg-[#E62B07] transition-colors"
          >
            {editingQuestion ? "Actualizar Pregunta" : "Crear Nueva Pregunta"}
          </button>
        </form>

        <hr className="my-6 border-gray-200" />

        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-800">
            Preguntas Existentes
          </h3>
          {questions.map((question) => (
            <div
              key={question.id}
              className="border border-gray-200 rounded-md p-4 bg-gray-50 group hover:bg-white transition-colors"
            >
              <div className="flex justify-between items-start">
                <div className="flex-1">
                  <h4 className="font-medium text-gray-900">{question.text}</h4>
                  <div className="mt-2 text-sm text-gray-600">
                    <span className="bg-[#FF3008]/10 text-[#FF3008] px-2 py-1 rounded-md mr-2">
                      {question.type.toUpperCase()}
                    </span>
                    {question.options && (
                      <span className="mr-2">
                        Opciones: {question.options.join(", ")}
                      </span>
                    )}
                    {question.emojis && (
                      <span className="mr-2">
                        Emojis: {question.emojis.join(" ")}
                      </span>
                    )}
                    {question.answers_count > 0 && (
                      <span className="text-red-600 font-semibold">
                        (Tiene {question.answers_count} respuesta
                        {question.answers_count > 1 && "s"} registradas)
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex gap-2 items-center">
                  <button
                    onClick={() => handleEditQuestion(question)}
                    disabled={question.answers_count > 0}
                    className={`p-1 rounded-md transition-opacity ${
                      question.answers_count > 0
                        ? "cursor-not-allowed bg-gray-200"
                        : "opacity-0 group-hover:opacity-100 hover:bg-gray-100"
                    }`}
                    title={
                      question.answers_count > 0
                        ? "No se puede editar esta pregunta porque ya tiene respuestas"
                        : "Editar Pregunta"
                    }
                  >
                    <PencilIcon
                      className={`w-5 h-5 ${
                        question.answers_count > 0
                          ? "text-gray-400"
                          : "text-[#FF3008]"
                      }`}
                    />
                  </button>

                  <button
                    onClick={() => handleDeleteQuestion(question.id)}
                    className="opacity-0 group-hover:opacity-100 transition-opacity p-1 hover:bg-gray-100 rounded-md text-red-600"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Questions;
