import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet, useParams } from 'react-router-dom';
import { AuthProvider, AuthContext } from './auth/AuthContext';
import './App.css';

import Main from './components/Main';
import LoginSuperAdmin from './components/LoginSuperAdmin';
import HomeSuperAdmin from './components/HomeSuperAdmin';
import LoginEmpanadas from './components/empanadasdelpaco/LoginEmpanadas';
import ForgotPassword from './components/ForgotPassword';
import LoginManglar from './components/LoginManglar';
import Home from './components/Home';
import CouponDetail from './components/empanadasdelpaco/CouponDetail';
import ManualRegister from './components/empanadasdelpaco/ManualRegister';
import RegisterEmpanadas from './components/empanadasdelpaco/RegisterEmpanadas';
import RestaurantesYMas from './components/empanadasdelpaco/RestaurantesYMas';
import Raspaditas from './components/empanadasdelpaco/Raspaditas';
import IAMarketing from './components/empanadasdelpaco/IAMarketing';
import TarjetaDeContacto from './components/empanadasdelpaco/TarjetaDeContacto';
import IntegracionesRestaurante from './components/IntegracionesRestaurante';
import GiftCards from './components/empanadasdelpaco/GiftCards';
import PayCrest from './components/PayCrest';
import UserEmpanadasValidation from './components/empanadasdelpaco/UserEmpanadasValidation';
import Payments from './components/Payments';
import HomeEmpanadas from './components/empanadasdelpaco/HomeEmpanadas';
import EncuestasEmpanadas from './components/empanadasdelpaco/EncuestasEmpanadas';
import UploadComponentEmpanadas from './components/empanadasdelpaco/UploadComponentEmpanadas';
import LasEmpanadasDelPacoMenu from './components/empanadasdelpaco/LasEmpanadasDelPacoMenu';
import UploadQRCodeMenuEmpanadas from './components/empanadasdelpaco/UploadQRCodeMenuEmpanadas';
import DownloadLatestQRMenu from './components/empanadasdelpaco/DownloadLatestQRMenu';
import EncuestasEmpanadasDelPaco from './components/empanadasdelpaco/EncuestasEmpanadasDelPaco';
import EncuestasApp from './components/empanadasdelpaco/EncuestasApp';
import RespuestasEncuesta from './components/empanadasdelpaco/RespuestasEncuesta';
import ClienteFantasmaEmpanadasDelPaco from './components/empanadasdelpaco/ClienteFantasmaEmpanadasDelPaco';
import Unsubscribe from './components/empanadasdelpaco/Unsubscribe';
import HomeElManglar from './components/elmanglar/HomeElManglar';
import RegisterElManglar from './components/elmanglar/RegisterElManglar';
import LoginLaCostenita from './components/lacostenita/LoginLaCostenita';
import HomeLaCostenita from './components/lacostenita/HomeLaCostenita';
import UserlacostenitaValidation from './components/lacostenita/UserlacostenitaValidation';
import RegisterLaCostenita from './components/lacostenita/RegisterLaCostenita';
import LaCostenitaMenu from './components/lacostenita/LaCostenitaMenu';
import UploadComponentLaCostenita from './components/lacostenita/UploadComponentLaCostenita';
import UploadQRCodeMenuLaCostenita from './components/lacostenita/UploadQRCodeMenuLaCostenita';
import TarjetaDeContactoCostenita from './components/lacostenita/TarjetaDeContactoCostenita';
import DownloadLatestQRMenuLaCostenita from './components/lacostenita/DownloadLatestQRMenuLaCostenita';
import Unsubscribelacostenita from './components/lacostenita/Unsubscribelacostenita';
import LoginEncuestadoraXY from './components/encuestadoraxy/LoginEncuestadoraXY';
import HomeEncuestadoraXY from './components/encuestadoraxy/HomeEncuestadoraXY';
import RegisterEncuestadoraXY from './components/encuestadoraxy/RegisterEncuestadoraXY';
import EncuestasEncuestadoraXY from './components/encuestadoraxy/EncuestasEncuestadoraXY';
import RespuestasEncuestaXY from './components/encuestadoraxy/RespuestasEncuestaXY';
import EstadisticasEncuestaXY from './components/encuestadoraxy/EstadisticasEncuestaXY';
import NuevasMarcas from './components/NuevasMarcas';
import Promociones from './components/empanadasdelpaco/Promociones';
import CreateClient from './components/CreateClient';
import LoginDeLena from './components/delena/LoginDeLena';
import HomeDeLena from './components/delena/HomeDeLena';
import UserDeLenaValidation from './components/delena/UserDeLenaValidation';
import RegisterDeLena from './components/delena/RegisterDeLena';
import DeLenaMenu from './components/delena/DeLenaMenu';
import UploadComponentDeLena from './components/delena/UploadComponentDeLena';
import UploadQRCodeMenuDeLena from './components/delena/UploadQRCodeMenuDeLena';
import TarjetaDeContactoDeLena from './components/delena/TarjetaDeContactoDeLena';
import DownloadLatestQRMenuDeLena from './components/delena/DownloadLatestQRMenuDeLena';
import UnsubscribeDeLena from './components/delena/UnsubscribeDeLena';
import VerMenuDeLena from './components/delena/VerMenuDeLena'; 
import LoginFoodLove from './components/foodlove/LoginFoodLove';
import HomeFoodLove from './components/foodlove/HomeFoodLove';
import UserFoodLoveValidation from './components/foodlove/UserFoodLoveValidation';
import RegisterFoodLove from './components/foodlove/RegisterFoodLove';
import ValidatePaymentsFoodLove from './components/foodlove/ValidatePaymentsFoodLove';
import SaldosFoodLove from './components/foodlove/SaldosFoodLove';
import LoginEddysBBQ from './components/eddysbbq/LoginEddysBBQ';
import RegisterEddysBBQ from './components/eddysbbq/RegisterEddysBBQ';
import HomeEddysBBQ from './components/eddysbbq/HomeEddysBBQ';
import VerMenuEddys from './components/eddysbbq/VerMenuEddys';
import UploadComponentEddys from './components/eddysbbq/UploadComponentEddys';
import DownloadLatestQRMenuEddys from './components/eddysbbq/DownloadLatestQRMenuEddys';
import ProgramarComidas from './components/foodlove/ProgramarComidas'; 
import ProgramarEnvios from './components/foodlove/ProgramarEnvios';
import AdministracionEnvios from './components/foodlove/AdministracionEnvios'; // Nueva sección
import ConfigurarProteinas from './components/foodlove/ConfigurarProteinas'; // Nueva sección
import Repartidores from './components/foodlove/Repartidores'; // Nueva sección
import Geocercas from './components/foodlove/Geocercas'; // Nueva sección
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ConditionalRoute = ({ children }) => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <Navigate to="/main" />;
  } else if (user.client === 'Empanadas_del_paco') {
    return <HomeEmpanadas />;
  } else if (user.client === 'el_manglar') {
    return <HomeElManglar />;
  } else if (user.client === 'la_costenita') {
    return <HomeLaCostenita />;
  } else if (user.client === 'encuestadora_xy') {
    return <HomeEncuestadoraXY />;
  } else if (user.client === 'delena') {
    return <HomeDeLena />;
  } else if (user.client === 'foodlove') {
    return <HomeFoodLove />;
  } else if (user.client === 'eddys_bbq') {
    return <HomeEddysBBQ />;
  } else {
    return children || <Outlet />;
  }
};

const LoginSelector = () => {
  const { client } = useParams();
  if (client === 'Empanadas_del_paco') {
    return <LoginEmpanadas />;
  } else if (client === 'el_manglar') {
    return <LoginManglar />;
  } else if (client === 'la_costenita') {
    return <LoginLaCostenita />;
  } else if (client === 'encuestadora_xy') {
    return <LoginEncuestadoraXY />;
  } else if (client === 'delena') {
    return <LoginDeLena />;
  } else if (client === 'foodlove') {
    return <LoginFoodLove />;
  } else if (client === 'eddys_bbq') {
    return <LoginEddysBBQ />;
  } else {
    return <Navigate to="/main" />;
  }
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/main" element={<Main />} />
          <Route path="/login_siemprecondescuentos" element={<LoginSuperAdmin />} />
          <Route path="/home_siemprecondescuentos" element={<HomeSuperAdmin />} />
          <Route path="/" element={<ConditionalRoute><Home /></ConditionalRoute>} />
          <Route path="/main" element={<Main />} />
          <Route path="/login/:client" element={<LoginSelector />} />
          <Route path="/forgot-password/:client" element={<ForgotPassword />} />
          <Route path="/coupon" element={<CouponDetail />} />
          <Route path="/restaurantes" element={<RestaurantesYMas />} />
          <Route path="/raspaditas" element={<Raspaditas />} />
          <Route path="/iamarketing" element={<IAMarketing />} />
          <Route path="/tarjeta-de-contacto" element={<TarjetaDeContacto />} />
          <Route path="/integraciones-restaurante" element={<IntegracionesRestaurante />} />
          <Route path="/register" element={<ManualRegister />} />
          <Route path="/register-empanadas" element={<RegisterEmpanadas />} />
          <Route path="/register-la_costenita" element={<RegisterLaCostenita />} />
          <Route path="/register-encuestadora_xy" element={<RegisterEncuestadoraXY />} />
          <Route path="/register-delena" element={<RegisterDeLena />} />
          <Route path="/register-foodlove" element={<RegisterFoodLove />} />
          <Route path="/gift-cards" element={<GiftCards />} />
          <Route path="/paycrest" element={<PayCrest />} />
          <Route path="/ValidadorCliente-LasEmpanadasDelPaco" element={<UserEmpanadasValidation />} />
          <Route path="/ValidadorCliente-LaCostenita" element={<UserlacostenitaValidation />} />
          <Route path="/ValidadorCliente-DeLena" element={<UserDeLenaValidation />} />
          <Route path="/ValidadorCliente-FoodLove" element={<UserFoodLoveValidation />} />
          <Route path="/pagos" element={<Payments />} />
          <Route path="/Encuestas-EmpanadasDelPaco" element={<EncuestasEmpanadas />} />
          <Route path="/UploadComponentEmpanadas" element={<UploadComponentEmpanadas />} />
          <Route path="/LasEmpanadasDelPaco_Menu" element={<LasEmpanadasDelPacoMenu />} />
          <Route path="/UploadQRCodeMenuEmpanadas" element={<UploadQRCodeMenuEmpanadas />} />
          <Route path="/DownloadLatestQRMenu" element={<DownloadLatestQRMenu />} />
          <Route path="/EncuestasEmpanadasDelPaco" element={<EncuestasEmpanadasDelPaco />} />
          <Route path="/EncuestasApp" element={<EncuestasApp />} />
          <Route path="/RespuestasEncuesta" element={<RespuestasEncuesta />} />
          <Route path="/ClienteFantasmaEmpanadasDelPaco" element={<ClienteFantasmaEmpanadasDelPaco />} />
          <Route path="/Unsubscribe" element={<Unsubscribe />} />
          <Route path="/ElManglar" element={<RegisterElManglar />} />
          <Route path="/LaCostenitaMenu" element={<LaCostenitaMenu />} />
          <Route path="/DeLenaMenu" element={<DeLenaMenu />} />
          <Route path="/tarjeta-de-contacto-la-costenita" element={<TarjetaDeContactoCostenita />} />
          <Route path="/tarjeta-de-contacto-delena" element={<TarjetaDeContactoDeLena />} />
          <Route path="/UploadComponentLaCostenita" element={<UploadComponentLaCostenita />} />
          <Route path="/UploadComponentDeLena" element={<UploadComponentDeLena />} />
          <Route path="/UploadQRCodeMenuLaCostenita" element={<UploadQRCodeMenuLaCostenita />} />
          <Route path="/UploadQRCodeMenuDeLena" element={<UploadQRCodeMenuDeLena />} />
          <Route path="/DownloadLatestQRMenuLaCostenita" element={<DownloadLatestQRMenuLaCostenita />} />
          <Route path="/DownloadLatestQRMenuDeLena" element={<DownloadLatestQRMenuDeLena />} />
          <Route path="/Unsubscribelacostenita" element={<Unsubscribelacostenita />} />
          <Route path="/Unsubscribe_delena" element={<UnsubscribeDeLena />} />
          <Route path="/EncuestasEncuestadoraXY" element={<EncuestasEncuestadoraXY />} />
          <Route path="/RespuestasEncuestaXY" element={<RespuestasEncuestaXY />} />
          <Route path="/encuestasxy/:id/estadisticas" element={<EstadisticasEncuestaXY />} />
          <Route path="/nuevas-marcas" element={<NuevasMarcas />} />
          <Route path="/promociones" element={<Promociones />} />
          <Route path="/create-client" element={<CreateClient />} />
          <Route path="/ver-menu-delena" element={<VerMenuDeLena />} />
          <Route path="/home-la_costenita" element={<ConditionalRoute><HomeLaCostenita /></ConditionalRoute>} />
          <Route path="/home-empanadas" element={<ConditionalRoute><HomeEmpanadas /></ConditionalRoute>} />
          <Route path="/home-el_manglar" element={<ConditionalRoute><HomeElManglar /></ConditionalRoute>} />
          <Route path="/home-encuestadora_xy" element={<ConditionalRoute><HomeEncuestadoraXY /></ConditionalRoute>} />
          <Route path="/home-delena" element={<ConditionalRoute><HomeDeLena /></ConditionalRoute>} />
          <Route path="/home-foodlove" element={<ConditionalRoute><HomeFoodLove /></ConditionalRoute>} />
          <Route path="/validate-payments-foodlove" element={<ValidatePaymentsFoodLove />} />
          <Route path="/saldos-foodlove" element={<SaldosFoodLove />} />
          <Route path="/login/eddys_bbq" element={<LoginEddysBBQ />} />
          <Route path="/register-eddysbbq" element={<RegisterEddysBBQ />} />
          <Route path="/home-eddysbbq" element={<ConditionalRoute><HomeEddysBBQ /></ConditionalRoute>} />
          <Route path="/ver-menu-eddysbbq" element={<VerMenuEddys />} />
          <Route path="/UploadComponentEddysBBQ" element={<UploadComponentEddys />} />
          <Route path="/DownloadLatestQRMenuEddysBBQ" element={<DownloadLatestQRMenuEddys />} />
          <Route path="/programar-comidas" element={<ProgramarComidas />} />
          <Route path="/programar-envios" element={<ProgramarEnvios />} />
          <Route path="/administracion-envios" element={<AdministracionEnvios />} /> {/* Nueva Ruta */}
          <Route path="/configurar-proteinas" element={<ConfigurarProteinas />} /> {/* Nueva Ruta */}
          <Route path="/repartidores" element={<Repartidores />} /> {/* Nueva Ruta para gestionar repartidores */}
          <Route path="/geocercas" element={<Geocercas />} /> {/* Nueva Ruta para gestionar geocercas */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <ToastContainer />
      </Router>
    </AuthProvider>
  );
}

export default App;
