// src/pages/ClientsList.jsx

import React, { useEffect, useState } from "react";
import api from "../services/api";
import { jsPDF } from "jspdf";

function ClientsList() {
  const [clients, setClients] = useState([]);
  const [error, setError] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [modalClient, setModalClient] = useState(null);
  const [locations, setLocations] = useState([]);

  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [uniqueUrl, setUniqueUrl] = useState("");
  const [modalError, setModalError] = useState("");

  const fetchClients = async () => {
    setError("");
    try {
      const resp = await api.get("/clients");
      setClients(resp.data);
    } catch (err) {
      setError("Error al cargar lista de clientes");
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleUpdatePlan = async (clientId, newPlan) => {
    try {
      await api.patch(`/clients/${clientId}/update-plan`, {
        plan_type: newPlan,
      });
      fetchClients();
    } catch (err) {
      alert("No se pudo actualizar el plan");
    }
  };

  const handleToggleActive = async (clientId) => {
    try {
      await api.patch(`/clients/${clientId}/toggle-active`);
      fetchClients();
    } catch (err) {
      alert("No se pudo cambiar el estado (activo/inactivo).");
    }
  };

  const handleDeleteClient = async (clientId) => {
    if (window.confirm("¿Seguro deseas eliminar este cliente?")) {
      try {
        await api.delete(`/clients/${clientId}`);
        fetchClients();
      } catch (err) {
        alert("No se pudo eliminar el cliente.");
      }
    }
  };

  const handleOpenLocationsModal = async (client) => {
    setModalClient(client);
    setModalError("");
    setShowModal(true);
    setLocations([]);
    setSelectedLocationId(null);
    setQrCodeBase64("");
    setUniqueUrl("");

    try {
      const resp = await api.get(`/locations/clients/${client.id}/locations`);
      setLocations(resp.data);
    } catch (err) {
      setModalError("Error al cargar sucursales de este cliente.");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setModalClient(null);
    setLocations([]);
    setSelectedLocationId(null);
    setQrCodeBase64("");
    setUniqueUrl("");
    setModalError("");
  };

  const handleGenerateQr = async (locationId) => {
    setModalError("");
    try {
      const resp = await api.get(`/locations/${locationId}/generate-qr`);
      setSelectedLocationId(locationId);
      setQrCodeBase64(resp.data.qr_code_base64);
      setUniqueUrl(resp.data.unique_url);
    } catch (error) {
      setModalError("Error al generar QR. Intenta nuevamente.");
    }
  };

  const handleDownloadSelect = (e) => {
    const format = e.target.value;
    if (!format) return;

    if (!qrCodeBase64) {
      setModalError("No se ha generado aún el código QR.");
      e.target.value = "";
      return;
    }

    const base64Data = `data:image/png;base64,${qrCodeBase64}`;
    if (format === "jpg") {
      const link = document.createElement("a");
      link.href = base64Data;
      link.download = "qr_code.jpg";
      link.click();
    } else if (format === "pdf") {
      const doc = new jsPDF();
      doc.addImage(base64Data, "PNG", 10, 10, 50, 50);
      doc.save("qr_code.pdf");
    }
    e.target.value = "";
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Panel de Clientes / Marcas</h2>
      {error && (
        <div className="text-red-600 mb-4 border border-red-200 bg-red-50 p-2 rounded">
          {error}
        </div>
      )}

      <table className="w-full text-left text-sm border">
        <thead>
          <tr className="border-b bg-gray-100">
            <th className="py-2 px-2">ID</th>
            <th className="py-2 px-2">Marca</th>
            <th className="py-2 px-2">Empresa</th>
            <th className="py-2 px-2">Email</th>
            <th className="py-2 px-2">Teléfono</th>
            <th className="py-2 px-2">Dirección</th>
            <th className="py-2 px-2">Plan</th>
            <th className="py-2 px-2">Status</th>
            <th className="py-2 px-2">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clients.map((c) => {
            const planType = c.admins?.[0]?.plan_type || "free";
            return (
              <tr key={c.id} className="border-b">
                <td className="py-2 px-2">{c.id}</td>
                <td className="py-2 px-2">{c.brand_name}</td>
                <td className="py-2 px-2">{c.name}</td>
                <td className="py-2 px-2">{c.email}</td>
                <td className="py-2 px-2">{c.phone || "-"}</td>
                <td className="py-2 px-2">{c.address || "-"}</td>

                {/* Plan */}
                <td className="py-2 px-2 capitalize">{planType}</td>

                {/* Status Activo/Inactivo */}
                <td className="py-2 px-2">
                  {c.is_active ? "Activo" : "Inactivo"}
                </td>

                <td className="py-2 px-2 flex flex-col gap-2 sm:flex-row sm:items-center sm:gap-1">
                  {/* Botón para cambiar plan */}
                  {planType === "free" ? (
                    <>
                      <button
                        onClick={() => handleUpdatePlan(c.id, "premium")}
                        className="px-2 py-1 bg-green-600 text-white rounded text-xs"
                        title="Cambiar de Free a Premium"
                      >
                        Pasar a Premium
                      </button>
                      <button
                        onClick={() => handleUpdatePlan(c.id, "platinium")}
                        className="px-2 py-1 bg-purple-600 text-white rounded text-xs"
                        title="Cambiar de Free a Platinium"
                      >
                        Pasar a Platinium
                      </button>
                    </>
                  ) : planType === "platinium" ? (
                    <button
                      onClick={() => handleUpdatePlan(c.id, "free")}
                      className="px-2 py-1 bg-yellow-600 text-white rounded text-xs"
                      title="Cambiar de Platinium a Free"
                    >
                      Pasar a Free
                    </button>
                  ) : (
                    <button
                      onClick={() => handleUpdatePlan(c.id, "free")}
                      className="px-2 py-1 bg-yellow-600 text-white rounded text-xs"
                      title="Cambiar de Premium a Free"
                    >
                      Pasar a Free
                    </button>
                  )}

                  {/* Botón toggle activo/inactivo */}
                  <button
                    onClick={() => handleToggleActive(c.id)}
                    className="px-2 py-1 bg-blue-600 text-white rounded text-xs"
                    title="Activar/Desactivar"
                  >
                    {c.is_active ? "Desactivar" : "Activar"}
                  </button>

                  {/* Botón ver sucursales */}
                  <button
                    onClick={() => handleOpenLocationsModal(c)}
                    className="px-2 py-1 bg-purple-600 text-white rounded text-xs"
                  >
                    Ver Sucursales
                  </button>

                  {/* Eliminar cliente */}
                  <button
                    onClick={() => handleDeleteClient(c.id)}
                    className="px-2 py-1 bg-red-600 text-white rounded text-xs"
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {showModal && modalClient && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-md p-6 max-w-md w-full relative">
            <h2 className="text-xl font-bold mb-4">
              Sucursales de: {modalClient.brand_name}
            </h2>
            {modalError && (
              <div className="text-red-600 mb-2 border border-red-200 bg-red-50 p-2 rounded">
                {modalError}
              </div>
            )}

            {locations.length === 0 ? (
              <p className="text-gray-500 text-sm">
                No hay sucursales registradas o error al cargarlas.
              </p>
            ) : (
              <table className="w-full text-left text-sm border">
                <thead>
                  <tr className="border-b bg-gray-100">
                    <th className="py-1 px-2">Sucursal</th>
                    <th className="py-1 px-2">URL</th>
                    <th className="py-1 px-2">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {locations.map((loc) => (
                    <tr key={loc.id} className="border-b">
                      <td className="py-1 px-2">{loc.name}</td>
                      <td className="py-1 px-2">
                        {loc.unique_url ? (
                          <a
                            href={loc.unique_url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-blue-600 underline"
                          >
                            {loc.unique_url}
                          </a>
                        ) : (
                          "No generada"
                        )}
                      </td>
                      <td className="py-1 px-2">
                        <button
                          onClick={() => handleGenerateQr(loc.id)}
                          className="px-2 py-1 bg-[#FF3008] text-white rounded text-xs"
                        >
                          Generar QR
                        </button>
                        {selectedLocationId === loc.id && qrCodeBase64 && (
                          <div className="mt-2">
                            <img
                              src={`data:image/png;base64,${qrCodeBase64}`}
                              alt="QR Code"
                              className="w-24 h-24 mb-1"
                            />
                            {uniqueUrl && (
                              <p className="text-xs text-gray-600 mb-2 break-all">
                                <strong>URL: </strong>
                                <a
                                  href={uniqueUrl}
                                  target="_blank"
                                  rel="noreferrer"
                                  className="text-blue-600 underline"
                                >
                                  {uniqueUrl}
                                </a>
                              </p>
                            )}
                            <label className="block text-xs font-medium text-gray-700 mb-1">
                              Descargar como:
                            </label>
                            <select
                              onChange={handleDownloadSelect}
                              className="block w-full p-1 border border-gray-300 rounded"
                            >
                              <option value="">-- Selecciona formato --</option>
                              <option value="jpg">JPG</option>
                              <option value="pdf">PDF</option>
                            </select>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClientsList;
