// src/contexts/AuthContext.jsx

import React, { createContext, useState, useEffect, useCallback } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [addresses, setAddresses] = useState([]); // Nuevo estado para direcciones
  const [isLoading, setIsLoading] = useState(true);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  const setAuthToken = (token) => {
    localStorage.setItem('authToken', token);
  };

  const getAuthToken = () => {
    return localStorage.getItem('authToken');
  };

  const setClientToStorage = (clientName) => {
    localStorage.setItem('client', clientName);
  };

  const getClientFromStorage = () => {
    return localStorage.getItem('client');
  };

  // Función para obtener las direcciones del usuario
  const fetchUserAddresses = useCallback(async (userId, token) => {
    try {
      const response = await fetch(`${backendUrl}/api/users/${userId}/addresses`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        setAddresses(data.addresses);
        console.log('Direcciones del usuario obtenidas correctamente:', data.addresses);
      } else {
        const errorData = await response.json();
        console.error(`Error al obtener direcciones: ${errorData.message}`);
      }
    } catch (error) {
      console.error(`Error en fetchUserAddresses: ${error.message}`);
    }
  }, [backendUrl]);

  const login = async (email, password, clientName) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${backendUrl}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, client: clientName }),
      });

      const data = await response.json();
      if (response.ok) {
        setAuthToken(data.token);
        // Asegurarse de que data.user contiene el campo 'id'
        if (data.user && data.user.id) {
          setUser(data.user);
          console.log('Usuario autenticado correctamente:', data.user);
          // Obtener direcciones después de un login exitoso
          await fetchUserAddresses(data.user.id, data.token || getAuthToken());
        } else {
          console.error('La respuesta del backend no contiene el ID del usuario.');
          setUser(null);
          return { success: false, message: 'Error en la respuesta del servidor.' };
        }
        setClient(clientName);
        setClientToStorage(clientName);
        return { success: true, user: data.user };
      } else {
        console.error(`Error durante el login: ${data.message}`);
        return { success: false, message: data.message };
      }
    } catch (error) {
      console.error(`Error de login: ${error.message}`);
      return { success: false, message: error.message };
    } finally {
      setIsLoading(false);
    }
  };

  const logout = useCallback(() => {
    setIsLoading(true);
    localStorage.removeItem('authToken');
    localStorage.removeItem('client');
    setUser(null);
    setClient(null);
    setAddresses([]); // Limpiar direcciones al cerrar sesión
    setIsLoading(false);
  }, []);

  const checkAuthStatus = useCallback(async () => {
    const token = getAuthToken();
    const storedClient = getClientFromStorage();
    if (token) {
      setIsLoading(true);
      try {
        const response = await fetch(`${backendUrl}/validate-token`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        if (response.ok) {
          if (data.user && data.user.id) {
            setUser(data.user);
            console.log('Estado de autenticación válido:', data.user);
            // Obtener direcciones después de validar el token
            await fetchUserAddresses(data.user.id, token);
          } else {
            console.error('La respuesta de validación de token no contiene el ID del usuario.');
            setUser(null);
            logout();
          }
          setClient(storedClient);
        } else {
          console.error(`Error en la validación del token: ${data.message}`);
          logout();
        }
      } catch (error) {
        console.error(`Error en la validación del token: ${error.message}`);
        logout();
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [backendUrl, fetchUserAddresses, logout]);

  useEffect(() => {
    checkAuthStatus();
  }, [checkAuthStatus]);

  const getHomeRoute = useCallback(() => {
    switch(client) {
      case 'Empanadas_del_paco':
        return '/home-empanadas';
      case 'el_manglar':
        return '/home-el_manglar';
      case 'la_costenita':
        return '/home-la_costenita';
      case 'encuestadora_xy':
        return '/home-encuestadora_xy';
      case 'delena':
        return '/home-delena';
      case 'foodlove':
        return '/home-foodlove';
      case 'eddys_bbq':
        return '/home-eddysbbq';
      default:
        return '/';
    }
  }, [client]);

  return (
    <AuthContext.Provider value={{ 
      user, 
      setUser, 
      client, 
      setClient, 
      addresses, // Proveer direcciones
      setAddresses, // Proveer función para actualizar direcciones si es necesario
      isLoading, 
      login, 
      logout,
      checkAuthStatus,
      getHomeRoute
    }}>
      {children}
    </AuthContext.Provider>
  );
};  
