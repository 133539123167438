import React, { useState, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import whatsappIcon from './whatsapp-icon.png';
import foodloveLogo from './foodlove.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './LoginFoodLove.module.css'; 

const LoginFoodLove = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const { client } = useParams();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoginError('');
  
    const response = await login(email, password, client);
  
    if (response.success && response.user) {
      navigate(`/home-foodlove`);
    } else {
      setLoginError(response.message || 'Error desconocido al iniciar sesión. Intente de nuevo.');
    }
  };

  const handleRegister = () => {
    navigate(`/register-foodlove`);
  };

  const handleWhatsAppRedirect = () => {
    window.location.href = 'https://wa.me/593993728427';
  };

  const handleForgotPassword = () => {
    navigate(`/forgot-password/${client}`);
  };

  const handleCloseLoginForm = () => {
    setShowLoginForm(false);
  };

  return (
    <div className={styles.loginFoodLovePage}>
      <header className={styles.loginFoodLoveHeader}>
        <img src={foodloveLogo} alt="Food Love Logo" className={styles.foodloveLogo} />
        <div className={`${styles.buttonGroup} container d-flex justify-content-center flex-wrap`}>
          <button className={`${styles.loginFoodLoveButton} m-2`} onClick={handleRegister}>
            Registrarse
          </button>
          <button
            className={`${styles.loginFoodLoveButton} m-2`}
            onClick={handleWhatsAppRedirect}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <img src={whatsappIcon} alt="WhatsApp" style={{ height: '24px' }} />
            Contactar
          </button>
          <button className={`${styles.loginFoodLoveButton} m-2`} onClick={() => setShowLoginForm(!showLoginForm)}>
            Iniciar Sesión
          </button>
        </div>
      </header>

      {showLoginForm && (
        <div className={`row ${styles.loginFormContainer}`}>
          <div className="col-12 position-relative">
            <button className={styles.closeButton} onClick={handleCloseLoginForm}>×</button>
            <form onSubmit={handleLogin}>
              <div className="form-group mb-3">
                <label htmlFor="email">Correo electrónico</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="password">Contraseña</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              {loginError && <p className="text-danger">{loginError}</p>}
              <div className="d-flex justify-content-between align-items-center mb-3">
                <button type="submit" className={styles.loginFoodLoveButton}>
                  Iniciar Sesión
                </button>
                <button
                  type="button"
                  className="btn btn-link p-0"
                  onClick={handleForgotPassword}
                  style={{ color: '#007bff', textDecoration: 'none', cursor: 'pointer' }}
                >
                  Olvidé mi contraseña
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="container mx-auto my-4">
        <iframe 
          width="100%" 
          height="315" 
          src="https://www.youtube.com/embed/7DRLUUUO10c" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          allowFullScreen
        ></iframe>
      </div>

      <footer className={`container text-center py-8 ${styles.loginFoodLoveFooter}`}>
      </footer>
    </div>
  );
};

export default LoginFoodLove;