import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  TileLayer,
  Marker,
  Polygon,
  Polyline,
  useMap,
  useMapEvents
} from 'react-leaflet';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { AuthContext } from '../../auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import LoadingSpinner from './LoadingSpinner';
import Notification from './Notification';

// Configuración del Icono Predeterminado
let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = DefaultIcon;

// Coordenadas del Norte de Quito, Ecuador
const INITIAL_CENTER = [-0.1807, -78.4678]; // Coordenadas aproximadas del norte de Quito

const ProgramarEnvios = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [orders, setOrders] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [routeCoordinates, setRouteCoordinates] = useState([]);
  const [loadingRoute, setLoadingRoute] = useState(false);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [startingPoint, setStartingPoint] = useState(INITIAL_CENTER);
  const [startingAddress, setStartingAddress] = useState('');
  const [repartidores, setRepartidores] = useState([]); // Repartidores
  const [selectedRepartidor, setSelectedRepartidor] = useState(''); // Repartidor seleccionado
  const [geocerca, setGeocerca] = useState([]); // Geocerca del repartidor seleccionado
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const mapRef = useRef(null);

  // Verificación de Permisos de Usuario
  useEffect(() => {
    if (user?.role !== 'admin' && user?.role !== 'superadmin') {
      Notification.error('No tienes permisos para acceder a esta sección.');
      navigate('/home-foodlove');
    }
  }, [user, navigate]);

  // Fetch de repartidores
  useEffect(() => {
    const fetchRepartidores = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/repartidores`);
        const data = await response.json();
        setRepartidores(data);
      } catch (error) {
        Notification.error('Error al cargar los repartidores.');
      }
    };
    fetchRepartidores();
  }, [backendUrl]);

  // Fetch de la geocerca del repartidor seleccionado
  useEffect(() => {
    if (!selectedRepartidor) return;
    const fetchGeocerca = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/geocercas?repartidorId=${selectedRepartidor}`);
        const data = await response.json();
        setGeocerca(typeof data.puntos === 'string' ? JSON.parse(data.puntos) : data.puntos);
      } catch (error) {
        Notification.error('Error al cargar la geocerca del repartidor.');
      }
    };
    fetchGeocerca();
  }, [selectedRepartidor, backendUrl]);

  // Fetch de Pedidos según la Fecha Seleccionada y el Repartidor Seleccionado
  useEffect(() => {
    if (!selectedRepartidor) return; // Asegurarse de que se seleccionó un repartidor
    const fetchOrders = async () => {
      setLoadingOrders(true);
      try {
        const formattedDate = selectedDate.toISOString().split('T')[0];
        const response = await fetch(`${backendUrl}/api/orders?date=${formattedDate}&repartidorId=${selectedRepartidor}`);

        if (!response.ok) throw new Error(`Error en la respuesta del servidor: ${response.status}`);

        const data = await response.json();
        if (!Array.isArray(data)) throw new Error('Datos inválidos recibidos del servidor');

        // Filtrar pedidos que no tienen el estado 'Despachado' (insensible a mayúsculas)
        const pendingOrders = data.filter(order => order.status.toLowerCase() !== 'despachado');

        setOrders(pendingOrders);
      } catch (error) {
        Notification.error('Error al cargar los pedidos. Intente de nuevo.');
      } finally {
        setLoadingOrders(false);
      }
    };
    fetchOrders();
  }, [selectedDate, selectedRepartidor, backendUrl]);

  // Manejar Selección de Pedidos
  const handleOrderSelection = (orderId) => {
    setSelectedOrders((prevSelected) => {
      const newSelection = prevSelected.includes(orderId)
        ? prevSelected.filter((id) => id !== orderId)
        : [...prevSelected, orderId];
      return newSelection;
    });
  };

  // Calcular Ruta Óptima
  const calculateOptimalRoute = async () => {
    setLoadingRoute(true);
    try {
      const selectedOrdersData = orders.filter((order) => selectedOrders.includes(order.id));

      const coordinates = selectedOrdersData
        .map((order) => {
          if (Array.isArray(order.map_location) && order.map_location.length === 2) {
            return [order.map_location[1], order.map_location[0]];
          } else if (order.map_location && typeof order.map_location === 'object') {
            return [order.map_location.y, order.map_location.x];
          } else {
            return null;
          }
        })
        .filter(coord => coord !== null);

      if (coordinates.length === 0) {
        throw new Error('No se encontraron coordenadas válidas.');
      }

      const uniqueCoordinates = Array.from(new Set(coordinates.map(coord => coord.join(',')))).map(str => str.split(',').map(Number));

      if (uniqueCoordinates.length === 0) {
        throw new Error('Se requieren al menos dos coordenadas únicas para calcular la ruta.');
      }

      const routeCoordinatesWithStart = [[startingPoint[0], startingPoint[1]], ...uniqueCoordinates];
      const response = await fetch(`${backendUrl}/api/calculate_route`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ coordinates: routeCoordinatesWithStart }),
      });

      const data = await response.json();
      if (response.ok && data.route) {
        setRouteCoordinates(data.route);
      } else if (response.ok && data.routeSummary) {
        Notification.info(data.message);
        setRouteCoordinates(routeCoordinatesWithStart);
      } else {
        throw new Error(data.message || 'Respuesta inválida del servidor');
      }
    } catch (error) {
      Notification.error(`Error al calcular la ruta: ${error.message}`);
    } finally {
      setLoadingRoute(false);
    }
  };

  // Despachar Pedidos Seleccionados
  const handleDispatchOrders = async () => {
    try {
      Notification.info('Se está preparando el despacho de los pedidos seleccionados en la ruta seleccionada.');

      const response = await fetch(`${backendUrl}/api/orders/dispatch`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ orderIds: selectedOrders }),
      });

      if (response.ok) {
        const result = await response.json();
        Notification.success(`Pedidos despachados correctamente. IDs actualizados: ${result.updatedIds.join(', ')}`);
        setOrders((prevOrders) =>
          prevOrders.filter(order => !selectedOrders.includes(order.id))
        );
        setSelectedOrders([]);
        setRouteCoordinates([]);
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error al despachar los pedidos.');
      }
    } catch (error) {
      Notification.error(`Error al despachar los pedidos: ${error.message}`);
    }
  };

  // Manejar Eventos en el Mapa (Seleccionar Punto de Inicio)
  const MapEvents = () => {
    useMapEvents({
      click: async (e) => {
        setStartingPoint([e.latlng.lat, e.latlng.lng]);

        try {
          const response = await fetch(`https://us1.locationiq.com/v1/reverse.php?key=YOUR_API_KEY&lat=${e.latlng.lat}&lon=${e.latlng.lng}&format=json`);
          if (!response.ok) throw new Error('Error al obtener la dirección');
          const data = await response.json();
          setStartingAddress(data.display_name);
        } catch (error) {
          Notification.error(`Error al obtener la dirección: ${error.message}`);
        }
      },
    });
    return null;
  };

  // Actualizar Vista del Mapa para Ajustar los Límites
  const UpdateMapView = () => {
    const map = useMap();
    useEffect(() => {
      if (routeCoordinates.length > 0) map.fitBounds(routeCoordinates);
    }, [routeCoordinates, map]);
    return null;
  };

  return (
    <div className="container mx-auto px-4 mt-10 text-white">
      <h2 className="text-center text-2xl font-bold mb-6">Programar Envíos</h2>

      {/* Selector de Fecha */}
      <div className="mb-6 relative z-50">
        <h5 className="text-lg font-semibold">Selecciona una fecha:</h5>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy-MM-dd"
          className="form-control mt-2 p-2 w-full border border-gray-300 rounded-lg text-black"
          popperClassName="z-50"
        />
      </div>

      {/* Selector de Repartidor */}
      <div className="mb-6">
        <h5 className="text-lg font-semibold">Selecciona un repartidor:</h5>
        <select
          className="form-control mt-2 p-2 w-full border border-gray-300 rounded-lg text-black"
          value={selectedRepartidor}
          onChange={(e) => setSelectedRepartidor(e.target.value)}
        >
          <option value="">Selecciona un repartidor</option>
          {repartidores.map((repartidor) => (
            <option key={repartidor.id} value={repartidor.id}>
              {repartidor.nombre}
            </option>
          ))}
        </select>
      </div>

      {/* Selección del Punto de Inicio en el Mapa */}
      <div className="mb-6 map-container relative z-0">
        <h5 className="text-lg font-semibold mb-2">Selecciona el punto de inicio:</h5>
        <MapContainer
          center={INITIAL_CENTER}
          zoom={13}
          className="leaflet-container w-full h-96 border border-gray-300 rounded-lg"
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
          />
          <Marker position={startingPoint} icon={DefaultIcon} />
          <MapEvents />
          <UpdateMapView />

          {/* Mostrar la geocerca si existe */}
          {geocerca.length > 0 && (
            <Polygon positions={geocerca.map(p => [p.lat, p.lng])} color="blue" />
          )}

          {/* Mostrar los pedidos en el mapa */}
          {orders.map((order, index) => (
            order.map_location && (
              <Marker key={index} position={[order.map_location[1], order.map_location[0]]} icon={DefaultIcon}>
              </Marker>
            )
          ))}
        </MapContainer>
        {startingAddress && (
          <p className="mt-2 text-sm">
            <strong>Dirección seleccionada:</strong> {startingAddress}
          </p>
        )}
      </div>

      {/* Listado de Pedidos para la Fecha Seleccionada */}
      <div className="mb-6">
        <h5 className="text-lg font-semibold">
          Pedidos para el {selectedDate.toLocaleDateString()}:
        </h5>
        {loadingOrders ? (
          <LoadingSpinner />
        ) : orders.length === 0 ? (
          <p>No hay pedidos para esta fecha.</p>
        ) : (
          <ul className="list-group mt-2 space-y-2">
            {orders.map((order) => (
              <li key={order.id} className="list-group-item flex justify-between bg-gray-800 text-white">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedOrders.includes(order.id)}
                    onChange={() => handleOrderSelection(order.id)}
                    className="mr-2"
                  />
                  <strong>Pedido #{order.id}</strong> - Dirección: {order.delivery_address} - Estado: {order.status}
                </div>
                {order.map_location ? (
                  <span> | Coordenadas: ({order.map_location[0]}, {order.map_location[1]})</span>
                ) : (
                  <span className="text-red-600"> | Coordenadas no disponibles</span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      {/* Botón para Calcular Ruta Óptima */}
      <button
        className="btn btn-primary mb-4 text-white"
        onClick={calculateOptimalRoute}
        disabled={loadingRoute || selectedOrders.length === 0}
      >
        {loadingRoute ? 'Calculando ruta...' : 'Calcular Ruta Óptima'}
      </button>

      {/* Mapa con la Ruta Calculada */}
      {routeCoordinates.length > 0 && (
        <div className="mb-4">
          <h5 className="text-lg font-semibold">Ruta calculada:</h5>
          <MapContainer
            center={routeCoordinates[0]}
            zoom={13}
            className="leaflet-container w-full h-96 border border-gray-300 rounded-lg"
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            {routeCoordinates.map((position, idx) => (
              <Marker key={idx} position={position} icon={DefaultIcon} />
            ))}
            <Polyline positions={routeCoordinates} color="blue" />
            <UpdateMapView />
          </MapContainer>
        </div>
      )}

      {/* Botón para Despachar Pedidos */}
      <button
        className="btn btn-success text-white"
        onClick={handleDispatchOrders}
        disabled={selectedOrders.length === 0}
      >
        Despachar Pedidos
      </button>

      {/* Nuevo botón para regresar al Home de Food Love */}
      <button
        className="btn btn-secondary text-white mt-4"
        onClick={() => navigate('/home-foodlove')}
      >
        Regresar al Home
      </button>
    </div>
  );
};
 
export default ProgramarEnvios;
