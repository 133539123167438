// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.configurar-proteinas-container {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .month-navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .calendar-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }
  
  .calendar-day {
    background-color: #f4f4f4;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
  }
  
  .empty-day {
    visibility: hidden;
  }
  
  .day-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .day-content.configured {
    background-color: #d3d3d3; /* Cambiar el color de fondo */
    cursor: not-allowed; /* Cambiar el cursor para indicar que no es editable */
  }
  
  .check-icon {
    color: green; /* Puedes cambiar el color del ícono */
  }
  
  .add-protein {
    color: blue; /* Color para los días que no tienen proteínas configuradas */
  }
  
  
  .protein-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/foodlove/ConfigurarProteinas.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,qCAAqC;IACrC,SAAS;EACX;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;EACjB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;;EAEA;IACE,yBAAyB,EAAE,8BAA8B;IACzD,mBAAmB,EAAE,sDAAsD;EAC7E;;EAEA;IACE,YAAY,EAAE,sCAAsC;EACtD;;EAEA;IACE,WAAW,EAAE,6DAA6D;EAC5E;;;EAGA;IACE,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;EAChB","sourcesContent":[".configurar-proteinas-container {\r\n    width: 100%;\r\n    max-width: 800px;\r\n    margin: 0 auto;\r\n  }\r\n  \r\n  .month-navigation {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .calendar-grid {\r\n    display: grid;\r\n    grid-template-columns: repeat(7, 1fr);\r\n    gap: 10px;\r\n  }\r\n  \r\n  .calendar-day {\r\n    background-color: #f4f4f4;\r\n    border-radius: 5px;\r\n    padding: 10px;\r\n    text-align: center;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .empty-day {\r\n    visibility: hidden;\r\n  }\r\n  \r\n  .day-content {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n  }\r\n  \r\n  .day-content.configured {\r\n    background-color: #d3d3d3; /* Cambiar el color de fondo */\r\n    cursor: not-allowed; /* Cambiar el cursor para indicar que no es editable */\r\n  }\r\n  \r\n  .check-icon {\r\n    color: green; /* Puedes cambiar el color del ícono */\r\n  }\r\n  \r\n  .add-protein {\r\n    color: blue; /* Color para los días que no tienen proteínas configuradas */\r\n  }\r\n  \r\n  \r\n  .protein-modal {\r\n    background-color: white;\r\n    padding: 20px;\r\n    border-radius: 10px;\r\n    max-width: 400px;\r\n    margin: 0 auto;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
