import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MapComponent from './MapComponent'; // Componente de mapa para seleccionar puntos
import 'bootstrap/dist/css/bootstrap.min.css';

const Repartidores = () => {
    const [repartidores, setRepartidores] = useState([]);
    const [nombreRepartidor, setNombreRepartidor] = useState('');
    const [puntos, setPuntos] = useState([]);
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    useEffect(() => {
        const fetchRepartidores = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/repartidores`);
                setRepartidores(response.data);
            } catch (error) {
                console.error("Error al cargar repartidores:", error);
            }
        };
        fetchRepartidores();
    }, [backendUrl]);

    const agregarPunto = (punto) => {
        // Añadir el punto si no está ya en la lista
        if (!puntos.some(p => p.lat === punto.lat && p.lng === punto.lng)) {
            setPuntos(prevPuntos => [...prevPuntos, punto]);
        } else {
            alert("Este punto ya ha sido seleccionado.");
        }
    };

    const guardarRepartidor = async () => {
        if (!nombreRepartidor) {
            alert("El nombre del repartidor es requerido.");
            return;
        }
        try {
            await axios.post(`${backendUrl}/api/repartidores`, {
                nombre: nombreRepartidor,
            });
            alert("Repartidor creado exitosamente.");
            setNombreRepartidor(''); // Limpiar nombre después de guardar
        } catch (error) {
            console.error("Error al guardar el repartidor:", error);
            alert("Error al crear el repartidor. Inténtalo de nuevo.");
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center">Crear Repartidor</h1>
            <div className="form-group mb-4">
                <label htmlFor="nombreRepartidor">Nombre del Repartidor:</label>
                <input
                    id="nombreRepartidor"
                    type="text"
                    className="form-control"
                    value={nombreRepartidor}
                    onChange={(e) => setNombreRepartidor(e.target.value)}
                    placeholder="Ingresa el nombre del repartidor"
                />
            </div>

            <MapComponent onClick={agregarPunto} puntos={puntos} />

            <button className="btn btn-primary mt-4" onClick={guardarRepartidor}>
                Guardar Repartidor
            </button>

            {puntos.length > 0 && (
                <div className="mt-4">
                    <h4>Puntos Seleccionados:</h4>
                    <ul>
                        {puntos.map((punto, index) => (
                            <li key={index}>
                                Lat: {punto.lat}, Lng: {punto.lng}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Repartidores;
