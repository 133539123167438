// src/pages/Login.jsx

import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import api from "../services/api";

function Login() {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post("/auth/login", { email, password });
            const { access_token, role, brand_id } = response.data;

            localStorage.setItem("token", access_token);
            localStorage.setItem("role", role);
            if (brand_id) localStorage.setItem("brandId", brand_id);

            if (role === "vendedor") {
                navigate("/select-client");
            } else {
                navigate("/dashboard");
            }
            
        } catch (error) {
            console.error("Error al iniciar sesión:", error);
            alert("Credenciales inválidas");
        }
    };

    return (
        <div className="flex flex-col min-h-screen bg-gray-50">
            <main className="flex-grow container mx-auto px-4 py-8 flex items-center justify-center">
                <div className="max-w-md w-full bg-white rounded-md shadow-md p-6">
                    <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Iniciar Sesión</h2>
                    <form onSubmit={handleSubmit} className="space-y-5">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Correo</label>
                            <input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">Contraseña</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                                required
                            />
                        </div>

                        {/* ENLACE NUEVO PARA OLVIDAR CONTRASEÑA */}
                        <div className="text-right">
                            <Link
                                to="/forgot-password"
                                className="text-sm text-blue-600 underline"
                            >
                                ¿Olvidaste tu contraseña?
                            </Link>
                        </div>

                        <button
                            type="submit"
                            className="w-full py-2 px-4 bg-[#FF3008] text-white font-medium rounded-md hover:bg-[#FF3008]/90"
                        >
                            Ingresar
                        </button>
                    </form>
                </div>
            </main>
        </div>
    );
}

export default Login;
