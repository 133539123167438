// src/pages/ForgotPassword.jsx

import React, { useState } from "react";
import api from "../services/api";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleSendReset = async (e) => {
    e.preventDefault();
    setMessage("");
    setErrorMsg("");
    try {
      const resp = await api.post("/auth/forgot-password", { email });
      // Si llega con éxito (status 200, detail en resp.data.detail)
      setMessage(resp.data.detail || "Enlace de recuperación enviado.");
      setEmail("");
    } catch (error) {
      if (error.response) {
        // Manejo de errores específicos
        if (error.response.status === 404) {
          setErrorMsg("No existe una cuenta asociada a ese correo.");
        } else if (error.response.status === 500) {
          setErrorMsg(error.response.data?.detail || "Error interno del servidor.");
        } else {
          setErrorMsg(error.response.data?.detail || "Error al solicitar la recuperación.");
        }
      } else {
        setErrorMsg("No se pudo conectar con el servidor.");
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <main className="flex-grow container mx-auto px-4 py-8 flex items-center justify-center">
        <div className="max-w-md w-full bg-white rounded-md shadow-md p-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Recuperar Contraseña
          </h2>
          <form onSubmit={handleSendReset} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Ingresa tu correo:
              </label>
              <input
                type="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-[#FF3008] text-white font-medium rounded-md hover:bg-[#FF3008]/90"
            >
              Enviar Enlace de Recuperación
            </button>
          </form>

          {/* Mensaje de éxito */}
          {message && (
            <div className="mt-4 p-2 border border-green-300 bg-green-50 text-sm text-green-800 rounded">
              {message}
            </div>
          )}

          {/* Mensaje de error */}
          {errorMsg && (
            <div className="mt-4 p-2 border border-red-300 bg-red-50 text-sm text-red-800 rounded">
              {errorMsg}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default ForgotPassword;
