// src/pages/Dashboard.jsx
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

/**
 * Procesa el array de answers y lo retorna en un formato
 * que el Dashboard usará para renderizar los gráficos.
 */
function processDataForDashboard(answersArray) {
  const grouped = {};

  answersArray.forEach((ans) => {
    const qId = ans.question_id;

    let parsedEmojis = ans.question.emojis || [];
    if (typeof parsedEmojis === "string") {
      try {
        parsedEmojis = JSON.parse(parsedEmojis);
      } catch {
        parsedEmojis = [];
      }
    }

    if (!grouped[qId]) {
      grouped[qId] = {
        questionId: qId,
        questionText: ans.question.text,
        questionType: ans.question.type,
        questionOptions: ans.question.options || [],
        questionEmojis: parsedEmojis,
        openResponses: [],
      };
    }
    grouped[qId].openResponses.push(ans.response_text);
  });

  return Object.values(grouped).map((group) => {
    const {
      questionId,
      questionText,
      questionType,
      questionOptions,
      questionEmojis,
      openResponses,
    } = group;

    const dataObject = {
      questionId,
      questionText,
      questionType,
      chartData: [],
      openResponses: [],
    };

    if (questionType === "open") {
      dataObject.openResponses = openResponses;
    } else {
      switch (questionType) {
        case "satisfaction": {
          const countsSat = {};
          openResponses.forEach((val) => {
            if (!countsSat[val]) countsSat[val] = 0;
            countsSat[val]++;
          });
          dataObject.chartData = Object.entries(countsSat).map(([emoji, count]) => ({
            name: emoji,
            value: count,
          }));
          break;
        }
        case "multiple":
        case "unique": {
          const countsMulti = {};
          questionOptions.forEach((opt) => (countsMulti[opt] = 0));
          openResponses.forEach((val) => {
            if (questionType === "multiple") {
              val.split(",").forEach((choice) => {
                const trimmed = choice.trim();
                if (countsMulti[trimmed] !== undefined) {
                  countsMulti[trimmed]++;
                }
              });
            } else {
              if (countsMulti[val] !== undefined) {
                countsMulti[val]++;
              }
            }
          });
          dataObject.chartData = Object.entries(countsMulti).map(([opt, cnt]) => ({
            name: opt,
            value: cnt,
          }));
          break;
        }
        case "scale": {
          const countsScale = {};
          openResponses.forEach((val) => {
            if (!countsScale[val]) countsScale[val] = 0;
            countsScale[val]++;
          });
          dataObject.chartData = Object.entries(countsScale).map(([opt, cnt]) => ({
            name: `Puntaje ${opt}`,
            value: cnt,
          }));
          break;
        }
        default: {
          dataObject.chartData = [
            { name: "Respuestas", value: openResponses.length },
          ];
          break;
        }
      }
    }
    return dataObject;
  });
}

function Dashboard() {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const storedBrandId = localStorage.getItem("brandId");

  const [locationsData, setLocationsData] = useState(null);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [answers, setAnswers] = useState([]);
  const [processedQuestions, setProcessedQuestions] = useState([]);

  useEffect(() => {
    if (!token || !role) {
      navigate("/login");
      return;
    }
    if ((role === "administrador" || role === "vendedor") && storedBrandId) {
      fetchLocations(storedBrandId);
    }
  }, [token, role, navigate, storedBrandId]);

  const fetchLocations = async (brandId) => {
    try {
      const resp = await api.get(`/dashboard/brand/${brandId}/locations`);
      setLocationsData(resp.data);
    } catch (err) {
      console.error("Error al obtener ubicaciones:", err);
      alert("Error al obtener ubicaciones");
    }
  };

  const fetchResponses = async () => {
    if (!storedBrandId) {
      alert("No se ha seleccionado ninguna marca");
      return;
    }
    try {
      const url = `/dashboard/brand/${storedBrandId}/responses${
        selectedLocationId ? `?location_id=${selectedLocationId}` : ""
      }`;
      const resp = await api.get(url);
      setAnswers(resp.data.answers || []);
    } catch (error) {
      console.error("Error al obtener respuestas:", error);
      alert("Error al obtener respuestas");
    }
  };

  useEffect(() => {
    if (answers.length > 0) {
      const finalData = processDataForDashboard(answers);
      setProcessedQuestions(finalData);
    } else {
      setProcessedQuestions([]);
    }
  }, [answers]);

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <div className="bg-white rounded-md shadow-md p-6">
          <h2 className="text-2xl font-bold mb-6">Dashboard de Resultados</h2>

          {locationsData && (
            <div className="mb-6">
              <h3 className="text-xl font-semibold mb-2">
                Marca: {locationsData.brand_name}
              </h3>
              <div className="flex flex-col sm:flex-row sm:items-end sm:gap-4">
                <div className="flex-1 mb-4 sm:mb-0">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Seleccione una sucursal
                  </label>
                  <select
                    value={selectedLocationId}
                    onChange={(e) => setSelectedLocationId(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md"
                  >
                    <option value="">Todas las sucursales</option>
                    {locationsData.locations.map((loc) => (
                      <option key={loc.id} value={loc.id}>
                        {loc.name}
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={fetchResponses}
                  className="px-4 py-2 bg-[#FF3008] text-white rounded-md hover:bg-[#FF3008]/90 transition-colors"
                >
                  Ver Respuestas
                </button>
              </div>
            </div>
          )}

          {answers.length > 0 ? (
            <div>
              <h3 className="text-xl font-semibold mb-4">
                Resultados de Encuestas (Total: {answers.length})
              </h3>
              {/* Para un layout moderno y responsivo, usamos grid con 1 col en móvil y 2 en pantallas grandes */}
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                {processedQuestions.map((q) => {
                  // CASO PREGUNTA ABIERTA
                  if (q.questionType === "open") {
                    return (
                      <div
                        key={q.questionId}
                        className="bg-gray-50 p-4 rounded-md shadow w-full lg:col-span-2"
                      >
                        <h4 className="text-lg font-semibold mb-2">
                          {q.questionText} (Respuestas Abiertas)
                        </h4>
                        {q.openResponses.length > 0 ? (
                          <ul className="list-disc list-inside space-y-1 pl-4">
                            {q.openResponses.map((resp, idx) => (
                              <li key={idx} className="text-gray-700">
                                {resp || "(vacío)"}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="text-gray-500 mt-2">Sin respuestas</p>
                        )}
                      </div>
                    );
                  }

                  // CASO GRAFICABLE
                  return (
                    <div
                      key={q.questionId}
                      className="bg-gray-50 p-4 rounded-md shadow w-full h-[350px] flex flex-col"
                    >
                      <h4 className="text-lg font-semibold mb-2">
                        {q.questionText} (Tipo: {q.questionType})
                      </h4>
                      <div className="flex-1">
                        <ResponsiveContainer width="100%" height="100%">
                          <BarChart data={q.chartData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis allowDecimals={false} />
                            <Tooltip />
                            <Legend />
                            <Bar
                              dataKey="value"
                              fill="#FF3008"
                              name="Cantidad"
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            <p className="text-gray-500">
              {locationsData
                ? "Selecciona una sucursal y haz clic en 'Ver Respuestas'."
                : "Cargando información de la marca..."}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
