// src/components/foodlove/AdministracionEnvios.js

import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Notification from './Notification';
import LoadingSpinner from './LoadingSpinner';
import { useNavigate } from 'react-router-dom';

const AdministracionEnvios = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [filters, setFilters] = useState({
    userId: '',
    orderId: '',
    startDate: null,
    endDate: null,
    status: '',
    minBalance: '',
    maxBalance: '',
  });
  const [loading, setLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const [expandedOrders, setExpandedOrders] = useState([]);
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const navigate = useNavigate();

  const groupDeliveries = (data) => {
    const grouped = {};

    data.forEach((item) => {
      const pedidoId = item.pedido_id;

      if (!grouped[pedidoId]) {
        grouped[pedidoId] = {
          id: pedidoId,
          user_id: item.user_id,
          fecha_pedido: item.fecha_pedido,
          total_pagar: parseFloat(item.total_pagar) || 0,
          saldo_usuario: parseFloat(item.saldo_usuario) || 0,
          saldo_despues_pedido: parseFloat(item.saldo_despues_pedido) || 0,
          status: item.status,
          delivery_address: item.delivery_address,
          map_location: item.map_location
            ? [parseFloat(item.map_location.y), parseFloat(item.map_location.x)]
            : [0, 0],
          additional_details: item.additional_details,
          fecha_entrega: item.fecha_entrega,
          direccion_entrega: item.direccion_entrega,
          productos: [],
        };
      }

      grouped[pedidoId].productos.push({
        product_id: item.product_id,
        producto_nombre: item.producto_nombre,
        producto_descripcion: item.producto_descripcion,
        producto_precio: parseFloat(item.producto_precio) || 0,
        cantidad: parseInt(item.cantidad, 10) || 0,
        subtotal: parseFloat(item.subtotal) || 0,
      });
    });

    return Object.values(grouped);
  };

  const fetchDeliveries = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams();

      if (filters.orderId) queryParams.append('orderId', filters.orderId);
      if (filters.userId) queryParams.append('userId', filters.userId);
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.startDate)
        queryParams.append('date', filters.startDate.toISOString().split('T')[0]);
      if (filters.endDate)
        queryParams.append('endDate', filters.endDate.toISOString().split('T')[0]);
      if (filters.minBalance) queryParams.append('minBalance', filters.minBalance);
      if (filters.maxBalance) queryParams.append('maxBalance', filters.maxBalance);

      const response = await fetch(
        `${backendUrl}/api/admin/orders/enriched?${queryParams.toString()}`
      );
      if (!response.ok) {
        if (response.status === 404) {
          setDeliveries([]);
          Notification.info(
            'No se encontraron pedidos que coincidan con los filtros aplicados.'
          );
          return;
        }
        throw new Error(`Error en la respuesta del servidor: ${response.status}`);
      }
      const data = await response.json();
      const groupedData = groupDeliveries(data);
      setDeliveries(groupedData);
    } catch (error) {
      Notification.error('Error al cargar los pedidos. Intente de nuevo.');
    } finally {
      setLoading(false);
    }
  };

  const fetchStatusOptions = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/admin/orders/status-options`);
      if (!response.ok)
        throw new Error(`Error al obtener opciones de status: ${response.status}`);
      const data = await response.json();
      setStatusOptions(data);
    } catch (error) {
      Notification.error('Error al cargar opciones de status.');
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date, name) => {
    setFilters((prev) => ({
      ...prev,
      [name]: date,
    }));
  };

  const toggleOrderExpansion = (orderId) => {
    setExpandedOrders((prev) => {
      if (prev.includes(orderId)) {
        return prev.filter((id) => id !== orderId);
      } else {
        return [...prev, orderId];
      }
    });
  };

  const handleDownloadExcel = () => {
    if (deliveries.length === 0) {
      Notification.info('No hay pedidos para descargar.');
      return;
    }

    const headers = [
      'Pedido ID',
      'User ID',
      'Fecha de Pedido',
      'Total a Pagar',
      'Saldo Usuario',
      'Saldo Después del Pedido',
      'Status',
      'Dirección de Entrega',
      'Ubicación en Mapa',
    ];

    const rows = deliveries.map((delivery) => [
      delivery.id,
      delivery.user_id,
      new Date(delivery.fecha_pedido).toLocaleDateString(),
      delivery.total_pagar,
      delivery.saldo_usuario,
      delivery.saldo_despues_pedido,
      delivery.status,
      delivery.delivery_address,
      delivery.map_location
        ? `https://www.google.com/maps/search/?api=1&query=${delivery.map_location[0]},${delivery.map_location[1]}`
        : 'N/A',
    ]);

    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [headers, ...rows].map((e) => e.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    const fecha = new Date().toISOString().split('T')[0];
    link.setAttribute('download', `pedidos_${fecha}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    fetchDeliveries();
    fetchStatusOptions();
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold mb-6 text-center">Administración de Pedidos</h2>

      <div className="bg-white p-6 rounded-md shadow-md mb-6">
        <h3 className="text-xl font-semibold mb-4">Filtros de Búsqueda</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div>
            <label className="block text-gray-700">ID de Usuario</label>
            <input
              type="text"
              name="userId"
              value={filters.userId}
              onChange={handleFilterChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholder="Ingrese el ID de usuario"
            />
          </div>

          <div>
            <label className="block text-gray-700">ID de Pedido</label>
            <input
              type="text"
              name="orderId"
              value={filters.orderId}
              onChange={handleFilterChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholder="Ingrese el ID de pedido"
            />
          </div>

          <div>
            <label className="block text-gray-700">Status del Pedido</label>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
            >
              <option value="">Todos</option>
              {statusOptions.map((status) => (
                <option key={status} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-gray-700">Fecha de Inicio</label>
            <DatePicker
              selected={filters.startDate}
              onChange={(date) => handleDateChange(date, 'startDate')}
              dateFormat="yyyy-MM-dd"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholderText="Selecciona la fecha de inicio"
            />
          </div>

          <div>
            <label className="block text-gray-700">Fecha de Fin</label>
            <DatePicker
              selected={filters.endDate}
              onChange={(date) => handleDateChange(date, 'endDate')}
              dateFormat="yyyy-MM-dd"
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholderText="Selecciona la fecha de fin"
            />
          </div>

          <div>
            <label className="block text-gray-700">Saldo Mínimo (USD)</label>
            <input
              type="number"
              name="minBalance"
              value={filters.minBalance}
              onChange={handleFilterChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholder="Ingrese el saldo mínimo"
              min="0"
            />
          </div>

          <div>
            <label className="block text-gray-700">Saldo Máximo (USD)</label>
            <input
              type="number"
              name="maxBalance"
              value={filters.maxBalance}
              onChange={handleFilterChange}
              className="mt-1 p-2 w-full border border-gray-300 rounded-md"
              placeholder="Ingrese el saldo máximo"
              min="0"
            />
          </div>
        </div>

        <div className="mt-6 flex justify-end space-x-4">
          <button
            onClick={fetchDeliveries}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Aplicar Filtros
          </button>
          <button
            onClick={() => {
              setFilters({
                userId: '',
                orderId: '',
                startDate: null,
                endDate: null,
                status: '',
                minBalance: '',
                maxBalance: '',
              });
              setExpandedOrders([]);
              fetchDeliveries();
            }}
            className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-600"
          >
            Limpiar Filtros
          </button>
          <button
            onClick={handleDownloadExcel}
            className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
          >
            Descargar Excel
          </button>
          <button
            onClick={() => navigate('/home-foodlove')}
            className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
          >
            Regresar a Home
          </button>
        </div>
      </div>

      <div className="bg-white p-6 rounded-md shadow-md">
        <h3 className="text-xl font-semibold mb-4">Lista de Pedidos</h3>
        {loading ? (
          <LoadingSpinner />
        ) : deliveries.length === 0 ? (
          <p>No hay pedidos que coincidan con los filtros aplicados.</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white">
              <thead>
                <tr>
                  <th className="py-2 px-4 border-b"></th>
                  <th className="py-2 px-4 border-b">Pedido ID</th>
                  <th className="py-2 px-4 border-b">User ID</th>
                  <th className="py-2 px-4 border-b">Fecha de Pedido</th>
                  <th className="py-2 px-4 border-b">Total a Pagar</th>
                  <th className="py-2 px-4 border-b">Saldo Usuario</th>
                  <th className="py-2 px-4 border-b">Saldo Después del Pedido</th>
                  <th className="py-2 px-4 border-b">Status</th>
                  <th className="py-2 px-4 border-b">Dirección de Entrega</th>
                  <th className="py-2 px-4 border-b">Ubicación en Mapa</th>
                </tr>
              </thead>
              <tbody>
                {deliveries.map((delivery) => (
                  <React.Fragment key={delivery.id}>
                    <tr className="text-center">
                      <td className="py-2 px-4 border-b">
                        <button
                          onClick={() => toggleOrderExpansion(delivery.id)}
                          className="text-blue-500 hover:text-blue-700 focus:outline-none"
                          aria-label={
                            expandedOrders.includes(delivery.id)
                              ? 'Cerrar detalles'
                              : 'Abrir detalles'
                          }
                        >
                          {expandedOrders.includes(delivery.id) ? '−' : '+'}
                        </button>
                      </td>
                      <td className="py-2 px-4 border-b">{delivery.id}</td>
                      <td className="py-2 px-4 border-b">{delivery.user_id}</td>
                      <td className="py-2 px-4 border-b">
                        {new Date(delivery.fecha_pedido).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {typeof delivery.total_pagar === 'number' && !isNaN(delivery.total_pagar)
                          ? `$${delivery.total_pagar.toFixed(2)}`
                          : '$0.00'}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {typeof delivery.saldo_usuario === 'number' && !isNaN(delivery.saldo_usuario)
                          ? `$${delivery.saldo_usuario.toFixed(2)}`
                          : '$0.00'}
                      </td>
                      <td className="py-2 px-4 border-b">
                        {typeof delivery.saldo_despues_pedido === 'number' &&
                        !isNaN(delivery.saldo_despues_pedido)
                          ? `$${delivery.saldo_despues_pedido.toFixed(2)}`
                          : '$0.00'}
                      </td>
                      <td className="py-2 px-4 border-b">{delivery.status}</td>
                      <td className="py-2 px-4 border-b">{delivery.delivery_address}</td>
                      <td className="py-2 px-4 border-b">
                        {delivery.map_location && delivery.map_location.length === 2 ? (
                          <a
                            href={`https://www.google.com/maps/search/?api=1&query=${delivery.map_location[0]},${delivery.map_location[1]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 underline"
                          >
                            Ver en Mapa
                          </a>
                        ) : (
                          'N/A'
                        )}
                      </td>
                    </tr>
                    {expandedOrders.includes(delivery.id) && (
                      <tr>
                        <td colSpan="10" className="py-2 px-4 border-b bg-gray-50">
                          {delivery.productos && delivery.productos.length > 0 ? (
                            <div>
                              <h4 className="text-lg font-semibold mb-2">Detalles del Pedido:</h4>
                              <table className="min-w-full bg-gray-100">
                                <thead>
                                  <tr>
                                    <th className="py-2 px-4 border-b">Nombre</th>
                                    <th className="py-2 px-4 border-b">Descripción</th>
                                    <th className="py-2 px-4 border-b">Precio</th>
                                    <th className="py-2 px-4 border-b">Cantidad</th>
                                    <th className="py-2 px-4 border-b">Subtotal</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {delivery.productos.map((producto) => (
                                    <tr key={producto.product_id} className="text-center">
                                      <td className="py-2 px-4 border-b">{producto.producto_nombre}</td>
                                      <td className="py-2 px-4 border-b">
                                        {producto.producto_descripcion}
                                      </td>
                                      <td className="py-2 px-4 border-b">
                                        {typeof producto.producto_precio === 'number' &&
                                        !isNaN(producto.producto_precio)
                                          ? `$${producto.producto_precio.toFixed(2)}`
                                          : '$0.00'}
                                      </td>
                                      <td className="py-2 px-4 border-b">
                                        {typeof producto.cantidad === 'number' &&
                                        !isNaN(producto.cantidad)
                                          ? producto.cantidad
                                          : '0'}
                                      </td>
                                      <td className="py-2 px-4 border-b">
                                        {typeof producto.subtotal === 'number' &&
                                        !isNaN(producto.subtotal)
                                          ? `$${producto.subtotal.toFixed(2)}`
                                          : '$0.00'}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>No hay productos asociados a este pedido.</p>
                          )}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdministracionEnvios;
