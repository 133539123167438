// src/pages/ResetPassword.jsx

import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../services/api";

function ResetPassword() {
  const { token } = useParams(); // token viene desde la ruta "/reset-password/:token"
  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [message, setMessage] = useState("");

  const handleReset = async (e) => {
    e.preventDefault();
    setMessage("");

    if (!token) {
      setMessage("Token no válido en la URL.");
      return;
    }
    if (newPassword !== confirmPass) {
      setMessage("Las contraseñas no coinciden.");
      return;
    }

    try {
      await api.post("/auth/reset-password", {
        token,
        new_password: newPassword,
      });
      alert("Tu contraseña ha sido cambiada con éxito. Ahora inicia sesión.");
      navigate("/login");
    } catch (error) {
      console.error("Error al resetear contraseña:", error);
      if (error.response && error.response.data && error.response.data.detail) {
        setMessage(error.response.data.detail);
      } else {
        setMessage("Ocurrió un error al resetear la contraseña.");
      }
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <main className="flex-grow container mx-auto px-4 py-8 flex items-center justify-center">
        <div className="max-w-md w-full bg-white rounded-md shadow-md p-6">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Restablecer Contraseña
          </h2>
          <form onSubmit={handleReset} className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Nueva Contraseña
              </label>
              <input
                type="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Confirmar Contraseña
              </label>
              <input
                type="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-[#FF3008] text-white font-medium rounded-md hover:bg-[#FF3008]/90"
            >
              Cambiar Contraseña
            </button>
          </form>

          {message && (
            <div className="mt-4 p-2 border border-gray-300 rounded bg-gray-50 text-sm text-gray-700">
              {message}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default ResetPassword;
