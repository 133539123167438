import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MapComponent from './MapComponent';
import 'bootstrap/dist/css/bootstrap.min.css';

const Geocercas = () => {
    const [repartidores, setRepartidores] = useState([]);
    const [selectedRepartidor, setSelectedRepartidor] = useState('');
    const [puntos, setPuntos] = useState([]);
    const [direcciones, setDirecciones] = useState([]);
    const [geocercasExistentes, setGeocercasExistentes] = useState([]);
    const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

    useEffect(() => {
        const fetchRepartidores = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/repartidores`);
                setRepartidores(response.data);
                console.log("Repartidores cargados:", response.data);
            } catch (error) {
                console.error("Error al cargar repartidores:", error);
            }
        };
        fetchRepartidores();
    }, [backendUrl]);

    useEffect(() => {
        const fetchGeocercas = async () => {
            try {
                const response = await axios.get(`${backendUrl}/api/geocercas`);
                setGeocercasExistentes(response.data);
                console.log("Geocercas existentes cargadas:", response.data);
            } catch (error) {
                console.error("Error al cargar geocercas:", error);
            }
        };
        fetchGeocercas();
    }, [backendUrl]);

    // Función para obtener la dirección desde Nominatim
    const obtenerDireccion = async (lat, lng) => {
        try {
            const response = await axios.get(`https://nominatim.openstreetmap.org/reverse`, {
                params: {
                    format: 'json',
                    lat: lat,
                    lon: lng,
                    addressdetails: 1
                }
            });
            if (response.data && response.data.display_name) {
                return response.data.display_name;
            } else {
                return 'Dirección no encontrada';
            }
        } catch (error) {
            console.error("Error al obtener la dirección:", error);
            return 'Error al obtener la dirección';
        }
    };

    // Función para agregar un punto y obtener su dirección
    const agregarPunto = async (latlng) => {
        console.log("Punto agregado:", latlng);
        const punto = { lat: latlng.lat, lng: latlng.lng };
        const direccion = await obtenerDireccion(punto.lat, punto.lng);

        setPuntos(prevPuntos => {
            if (!prevPuntos.some(p => p.lat === punto.lat && p.lng === punto.lng)) {
                const nuevosPuntos = [...prevPuntos, punto];
                setDirecciones(direcciones => [...direcciones, direccion]);
                console.log("Puntos actualizados:", nuevosPuntos);
                console.log("Direcciones actualizadas:", [...direcciones, direccion]);
                return nuevosPuntos;
            } else {
                alert("Este punto ya ha sido seleccionado.");
                return prevPuntos;
            }
        });
    };

    const guardarGeocerca = async () => {
        if (puntos.length < 3 || !selectedRepartidor) {
            alert("Selecciona un repartidor y al menos 3 puntos.");
            return;
        }
        try {
            await axios.post(`${backendUrl}/api/geocercas`, {
                repartidor: selectedRepartidor,
                puntos,
            });
            alert("Geocerca creada exitosamente.");
            setPuntos([]);
            setDirecciones([]);
            setSelectedRepartidor('');
        } catch (error) {
            console.error("Error al guardar la geocerca:", error);
            alert("Error al crear la geocerca. Inténtalo de nuevo.");
        }
    };

    // Generar un color aleatorio para cada geocerca
    const generarColorAleatorio = () => {
        const letras = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letras[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    return (
        <div className="container mt-4">
            <h1 className="text-center">Crear Geocerca</h1>
            <div className="form-group mb-4">
                <label htmlFor="repartidor">Selecciona un repartidor:</label>
                <select
                    id="repartidor"
                    className="form-control"
                    value={selectedRepartidor}
                    onChange={(e) => setSelectedRepartidor(e.target.value)}
                >
                    <option value="">Selecciona un repartidor</option>
                    {repartidores.map(repartidor => (
                        <option key={repartidor.id} value={repartidor.id}>
                            {repartidor.nombre}
                        </option>
                    ))}
                </select>
            </div>

            <MapComponent onClick={agregarPunto} puntos={puntos} />

            <button className="btn btn-primary mt-4" onClick={guardarGeocerca}>
                Guardar Geocerca
            </button>

            {puntos.length > 0 && (
                <div className="mt-4">
                    <h4>Puntos Seleccionados:</h4>
                    <ul>
                        {puntos.map((punto, index) => (
                            <li key={index}>
                                Lat: {punto.lat}, Lng: {punto.lng} - Dirección: {direcciones[index]}
                            </li>
                        ))}
                    </ul>
                    <pre>{JSON.stringify(puntos, null, 2)}</pre>
                </div>
            )}

            <h2 className="mt-5 text-center">Geocercas Existentes</h2>
            <div>
                {geocercasExistentes.length === 0 ? (
                    <p className="text-center">No hay geocercas existentes.</p>
                ) : (
                    geocercasExistentes.map((geocerca, index) => (
                        <MapComponent 
                            key={index}
                            puntos={typeof geocerca.puntos === 'string' ? JSON.parse(geocerca.puntos) : geocerca.puntos}
                            drawLines={true}
                            color={generarColorAleatorio()} // Genera un color aleatorio para cada geocerca
                        />
                    ))
                )}
            </div>
        </div>
    );
};

export default Geocercas;
