// src/pages/RegisterClient.jsx

import React, { useState } from "react";
import api from "../services/api";
import { jsPDF } from "jspdf";
import "./Modal.css";
import "../styles.css";

function RegisterClient() {
  // Datos básicos del formulario
  const [vendorId, setVendorId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [logoFile, setLogoFile] = useState(null);
  const [adminPassword, setAdminPassword] = useState("");

  // Manejo de sucursales
  const [locations, setLocations] = useState([{ name: "", address: "" }]);

  // Manejo de preguntas
  const [questions, setQuestions] = useState([
    {
      text: "",
      type: "satisfaction",
      optionList: [],
      min_value: "",
      max_value: "",
      emojis: "",
    },
  ]);

  // Manejo de plan
  const [planType, setPlanType] = useState("free");

  // Estados de carga/errores
  const [isUploadingLogo, setIsUploadingLogo] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Manejo de modales
  const [showFreeLimitAlert, setShowFreeLimitAlert] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showModalQR, setShowModalQR] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Datos para crear sucursales y generar QR
  const [registeredLocations, setRegisteredLocations] = useState([]);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [uniqueUrl, setUniqueUrl] = useState("");

  // Datos para saber a qué cliente se le han de crear las sucursales (en plan pagado)
  const [createdClientId, setCreatedClientId] = useState(null);

  // Para almacenar temporalmente el FormData si se requiere pago
  const [pendingFormData, setPendingFormData] = useState(null);

  // Emojis por defecto para tipo "satisfaction"
  const defaultEmojis = ["😠", "😞", "😐", "😊", "😍", "😁", "🤩", "🤬", "😡", "😃"];

  //--------------------------------------------------------------------------
  // Manejo de campos de Sucursales
  //--------------------------------------------------------------------------
  const handleAddLocation = () => {
    setLocations([...locations, { name: "", address: "" }]);
  };

  const handleLocationChange = (index, field, value) => {
    const newLocs = [...locations];
    newLocs[index][field] = value;
    setLocations(newLocs);
  };

  const handleRemoveLocation = (index) => {
    const updatedLocations = [...locations];
    updatedLocations.splice(index, 1);
    setLocations(updatedLocations);
  };

  //--------------------------------------------------------------------------
  // Manejo de campos de Preguntas
  //--------------------------------------------------------------------------
  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        text: "",
        type: "satisfaction",
        optionList: [],
        min_value: "",
        max_value: "",
        emojis: "",
      },
    ]);
  };

  const handleQuestionChange = (index, field, value) => {
    const newQs = [...questions];
    newQs[index][field] = value;
    setQuestions(newQs);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const handleAddOptionToQuestion = (qIndex) => {
    const newQs = [...questions];
    newQs[qIndex].optionList.push("");
    setQuestions(newQs);
  };

  const handleOptionChange = (qIndex, optIndex, value) => {
    const newQs = [...questions];
    newQs[qIndex].optionList[optIndex] = value;
    setQuestions(newQs);
  };

  const handleRemoveOption = (qIndex, optIndex) => {
    const newQs = [...questions];
    newQs[qIndex].optionList.splice(optIndex, 1);
    setQuestions(newQs);
  };

  const handleEmojiClick = (index, emoji) => {
    const q = questions[index];
    const emojisArr = q.emojis
      ? q.emojis.split(",").map((em) => em.trim()).filter(Boolean)
      : [];
    if (!emojisArr.includes(emoji)) {
      emojisArr.push(emoji);
      const updatedEmojis = emojisArr.join(", ");
      handleQuestionChange(index, "emojis", updatedEmojis);
    }
  };

  //--------------------------------------------------------------------------
  // Manejo de Logo
  //--------------------------------------------------------------------------
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploadingLogo(true);
      setTimeout(() => {
        setLogoFile(file);
        setIsUploadingLogo(false);
      }, 800);
    }
  };

  //--------------------------------------------------------------------------
  // Creación de sucursales (tras confirmar pago o si plan free)
  //--------------------------------------------------------------------------
  const createLocationsForClient = async (clientId) => {
    const locResponses = [];
    for (const loc of locations) {
      if (loc.name.trim() && loc.address.trim()) {
        const resLoc = await api.post(
          `/locations/clients/${clientId}/locations`,
          { name: loc.name, address: loc.address }
        );
        locResponses.push(resLoc.data);
      }
    }
    setRegisteredLocations(locResponses);
  };

  //--------------------------------------------------------------------------
  // Enviar formulario principal
  //--------------------------------------------------------------------------
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    // Validaciones de interfaz según plan:
    // Free / Platinium => máx 1 sucursal y máx 4 preguntas
    // Premium => sin límites
    if (planType === "free" || planType === "platinium") {
      if (locations.length > 1 || questions.length > 4) {
        setShowFreeLimitAlert(true);
        return;
      }
    }

    // Revisamos que haya logo
    if (!logoFile) {
      setErrorMessage("Debes adjuntar un archivo de imagen para el logo.");
      return;
    }

    // Creamos el formData con la info
    const formData = new FormData();
    formData.append("file", logoFile);
    formData.append("brand_name", brandName);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("address", address);
    formData.append("admin_password", adminPassword);
    if (vendorId.trim() !== "") {
      formData.append("vendor_id", vendorId);
    }

    // Armamos el payload de preguntas
    const newQuestionsPayload = questions.map((q) => {
      let emojisArray = null;
      if (q.type === "satisfaction" && q.emojis) {
        emojisArray = q.emojis
          .split(",")
          .map((em) => em.trim())
          .filter(Boolean);
      }
      return {
        text: q.text,
        type: q.type,
        options:
          (q.type === "multiple" || q.type === "unique") && q.optionList.length
            ? q.optionList
            : null,
        min_value: q.type === "scale" ? parseInt(q.min_value) || null : null,
        max_value: q.type === "scale" ? parseInt(q.max_value) || null : null,
        is_shared: true,
        emojis: q.type === "satisfaction" ? emojisArray : null,
      };
    });
    formData.append("questions", JSON.stringify(newQuestionsPayload));
    formData.append("plan_type", planType);

    // Lógica para plan
    if (planType === "free") {
      // Plan free: Registramos de inmediato
      await handleCreateClientAndLocations(formData);
    } else if (planType === "platinium" || planType === "premium") {
      // Mostramos modal de pago y guardamos formData pendiente
      setPendingFormData(formData);
      setShowPaymentModal(true);
    }
  };

  //--------------------------------------------------------------------------
  // Crear cliente y sus sucursales (para free o tras confirmar pago)
  //--------------------------------------------------------------------------
  const handleCreateClientAndLocations = async (formData) => {
    setIsRegistering(true);
    try {
      // Llamada al endpoint register-client
      const resClient = await api.post("/clients/register-client", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const clientId = resClient.data.id;
      setCreatedClientId(clientId);

      // Guardamos brandId en localStorage
      localStorage.setItem("brandId", clientId.toString());

      // Creamos sucursales
      await createLocationsForClient(clientId);

      // Si es plan free => pasamos directo a mostrar QR
      // Si es plan pending => igual (posteriormente)
      setShowModalQR(true);
      setShowSuccessModal(true);

      // Limpiamos el formulario
      resetForm();
    } catch (err) {
      let message = "Error al registrar el cliente.";
      if (!err.response) {
        message = "No se pudo conectar con el servidor. Verifica la API.";
      } else {
        if (err.response.status === 400) {
          message =
            err.response.data.detail ||
            "Datos inválidos. Verifica la información.";
        } else if (err.response.status === 409) {
          message = "El correo ingresado ya existe en la base de datos.";
        } else {
          if (
            typeof err.response.data === "string" &&
            err.response.data.includes("duplicate key value")
          ) {
            message = "Ya existe un cliente con ese correo.";
          } else if (
            typeof err.response.data?.detail === "string" &&
            err.response.data.detail.includes("duplicate key value")
          ) {
            message = "Ese correo ya se encuentra registrado.";
          } else if (err.response.data?.detail) {
            message = err.response.data.detail;
          }
        }
      }
      setErrorMessage(message);
    } finally {
      setIsRegistering(false);
    }
  };

  //--------------------------------------------------------------------------
  // Confirmación de pago (para platinium / premium)
  //--------------------------------------------------------------------------
  const handlePaymentConfirmed = async () => {
    // Aquí creamos el cliente con plan "pending" (o "platinium"/"premium" según server)
    // y las sucursales
    if (!pendingFormData) {
      setErrorMessage("No hay datos para registrar. Reintenta.");
      return;
    }
    setShowPaymentModal(false); // ocultamos modal de pago
    await handleCreateClientAndLocations(pendingFormData);
  };

  //--------------------------------------------------------------------------
  // Funciones para generar y descargar QR
  //--------------------------------------------------------------------------
  const handleGenerateQR = async () => {
    if (!selectedLocationId) {
      setErrorMessage("Selecciona una sucursal para generar el QR.");
      return;
    }
    try {
      const resp = await api.get(`/locations/${selectedLocationId}/generate-qr`);
      setQrCodeBase64(resp.data.qr_code_base64);
      setUniqueUrl(resp.data.unique_url);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(
        "Error al generar el código QR. Por favor, intenta nuevamente."
      );
    }
  };

  const handleDownloadSelect = (e) => {
    const format = e.target.value;
    if (!format) return;
    if (!qrCodeBase64) {
      setErrorMessage("No se ha generado aún el código QR.");
      e.target.value = "";
      return;
    }
    const base64Data = `data:image/png;base64,${qrCodeBase64}`;
    if (format === "jpg") {
      const link = document.createElement("a");
      link.href = base64Data;
      link.download = "qr_code.jpg";
      link.click();
    } else if (format === "pdf") {
      const doc = new jsPDF();
      doc.addImage(base64Data, "PNG", 10, 10, 50, 50);
      doc.save("qr_code.pdf");
    }
    e.target.value = "";
  };

  //--------------------------------------------------------------------------
  // Cierre de Modales y reset
  //--------------------------------------------------------------------------
  const closeModalQR = () => {
    setShowModalQR(false);
    setQrCodeBase64("");
    setUniqueUrl("");
    setSelectedLocationId("");
    setErrorMessage("");
  };

  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const closePaymentModal = () => {
    setShowPaymentModal(false);
  };

  const handleCloseLimitAlert = () => {
    setShowFreeLimitAlert(false);
  };

  const resetForm = () => {
    setVendorId("");
    setBrandName("");
    setName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setLogoFile(null);
    setAdminPassword("");
    setLocations([{ name: "", address: "" }]);
    setQuestions([
      {
        text: "",
        type: "satisfaction",
        optionList: [],
        min_value: "",
        max_value: "",
        emojis: "",
      },
    ]);
    setPlanType("free");
    setCreatedClientId(null);
    setPendingFormData(null);
  };

  //--------------------------------------------------------------------------
  // Render principal
  //--------------------------------------------------------------------------
  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="text-2xl font-bold mb-4">Registrar Cliente (Marca)</h2>

      {errorMessage && (
        <div className="mb-4 p-2 border border-red-300 bg-red-50 text-red-600 rounded">
          {errorMessage}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Vendor ID */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Vendor ID (opcional)
          </label>
          <input
            type="number"
            value={vendorId}
            onChange={(e) => setVendorId(e.target.value)}
            placeholder="Si se deja vacío, no se asocia a ningún vendedor"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Marca */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nombre de Marca
          </label>
          <input
            type="text"
            required
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Empresa */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Nombre de la Empresa
          </label>
          <input
            type="text"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Email */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Correo
          </label>
          <input
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Teléfono */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Teléfono
          </label>
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Dirección */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Dirección (principal)
          </label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Logo */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Logo (imagen)
          </label>
          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
            <div className="text-center">
              {isUploadingLogo && (
                <p className="text-sm text-gray-500 mb-2">Subiendo...</p>
              )}
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48"
                aria-hidden="true"
              >
                <path
                  d="M14 22v-6c0-1.1.9-2 2-2h3"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5 18h4l3 12h26l3-12h4"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="flex text-sm text-gray-600">
                <label
                  htmlFor="file-upload"
                  className="relative cursor-pointer rounded-md font-medium text-[#FF3008] focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2"
                >
                  <span>Subir un archivo</span>
                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="sr-only"
                    onChange={handleFileChange}
                  />
                </label>
                <p className="pl-1">o arrastrar y soltar</p>
              </div>
              <p className="text-xs text-gray-500">PNG, JPG hasta 10MB</p>
              {logoFile && !isUploadingLogo && (
                <p className="mt-2 text-sm text-green-600">
                  Archivo listo: {logoFile.name}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* Contraseña Admin */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Contraseña del Administrador
          </label>
          <input
            type="password"
            required
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          />
        </div>

        {/* Plan */}
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Tipo de Plan
          </label>
          <select
            value={planType}
            onChange={(e) => setPlanType(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
          >
            <option value="free">Plan Free</option>
            <option value="platinium">Plan Platinium</option>
            <option value="premium">Plan Premium</option>
          </select>
          <p className="text-xs text-gray-500 mt-1">
            <strong>ℹ️</strong>
            <br />
            - <strong>Free</strong>: Máx 1 sucursal, máx 4 preguntas, hasta 10
            respuestas totales.
            <br />
            - <strong>Platinium</strong>: Máx 1 sucursal, máx 4 preguntas, hasta
            20 respuestas totales (requiere pago).
            <br />
            - <strong>Premium</strong>: Ilimitado (sucursales, preguntas,
            respuestas), con pago previo.
          </p>
        </div>

        {/* Sucursales */}
        <div className="pt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-4">Sucursales</h3>
          <div className="space-y-4">
            {locations.map((loc, index) => (
              <div
                key={index}
                className="p-4 bg-gray-100 rounded-lg border border-gray-200"
              >
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Nombre Sucursal
                    </label>
                    <input
                      type="text"
                      value={loc.name}
                      onChange={(e) =>
                        handleLocationChange(index, "name", e.target.value)
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Dirección Sucursal
                    </label>
                    <input
                      type="text"
                      value={loc.address}
                      onChange={(e) =>
                        handleLocationChange(index, "address", e.target.value)
                      }
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    type="button"
                    onClick={() => handleRemoveLocation(index)}
                    className="text-red-600 hover:text-red-800 text-sm"
                  >
                    Eliminar sucursal
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddLocation}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-[#FF3008] bg-[#FF3008]/10 hover:bg-[#FF3008]/20"
            >
              + Agregar Sucursal
            </button>
          </div>
        </div>

        {/* Preguntas */}
        <div className="pt-6">
          <h3 className="text-lg font-medium text-gray-900 mb-2">
            Preguntas Personalizadas
          </h3>
          <div className="mb-4 p-3 bg-yellow-100 border border-yellow-300 text-sm text-yellow-800 rounded-md">
            <strong>Importante:</strong> Revisa la ortografía y sintaxis de cada
            pregunta. Una vez registradas y con respuestas, ¡no se podrán
            editar!
          </div>
          <div className="space-y-4">
            {questions.map((q, i) => (
              <div
                key={i}
                className="p-4 bg-gray-100 rounded-lg border border-gray-200"
              >
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Texto de la pregunta
                    </label>
                    <input
                      type="text"
                      value={q.text}
                      onChange={(e) => handleQuestionChange(i, "text", e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      placeholder="Ej: ¿Cómo calificarías nuestro servicio hoy?"
                    />
                    <p className="text-xs text-yellow-700 mt-1">
                      Asegúrate de escribir correctamente; no se podrá editar si
                      recibe respuestas.
                    </p>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Tipo de pregunta
                    </label>
                    <select
                      value={q.type}
                      onChange={(e) => handleQuestionChange(i, "type", e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                    >
                      <option value="satisfaction">
                        Satisfacción (Caritas)
                      </option>
                      <option value="multiple">Múltiple</option>
                      <option value="unique">Única</option>
                      <option value="open">Respuesta Abierta</option>
                      <option value="scale">Escala Personalizada</option>
                    </select>
                  </div>

                  {(q.type === "multiple" || q.type === "unique") && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Opciones
                      </label>
                      <p className="text-xs text-gray-500 mb-2">
                        Ingresa cada opción por separado:
                      </p>
                      {q.optionList.map((opt, optIndex) => (
                        <div key={optIndex} className="flex gap-2 mb-2">
                          <input
                            type="text"
                            value={opt}
                            onChange={(e) =>
                              handleOptionChange(i, optIndex, e.target.value)
                            }
                            className="flex-1 p-2 border border-gray-300 rounded-md"
                          />
                          <button
                            type="button"
                            onClick={() => handleRemoveOption(i, optIndex)}
                            className="px-2 bg-red-500 text-white rounded hover:bg-red-600"
                          >
                            X
                          </button>
                        </div>
                      ))}
                      <button
                        type="button"
                        onClick={() => handleAddOptionToQuestion(i)}
                        className="px-3 py-1 bg-[#FF3008] text-white rounded text-sm"
                      >
                        + Agregar Opción
                      </button>
                    </div>
                  )}

                  {q.type === "scale" && (
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Valor mínimo
                        </label>
                        <input
                          type="number"
                          value={q.min_value}
                          onChange={(e) =>
                            handleQuestionChange(i, "min_value", e.target.value)
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700">
                          Valor máximo
                        </label>
                        <input
                          type="number"
                          value={q.max_value}
                          onChange={(e) =>
                            handleQuestionChange(i, "max_value", e.target.value)
                          }
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                        />
                      </div>
                    </div>
                  )}

                  {q.type === "satisfaction" && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Caritas (emojis), separadas por coma
                      </label>
                      <input
                        type="text"
                        value={q.emojis}
                        onChange={(e) =>
                          handleQuestionChange(i, "emojis", e.target.value)
                        }
                        placeholder="Ej: 😠, 😞, 😐, 😊, 😍"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      />
                      {q.emojis && (
                        <div className="flex gap-4 mt-2 flex-wrap">
                          {q.emojis
                            .split(",")
                            .map((rawEm) => rawEm.trim())
                            .filter(Boolean)
                            .map((em, idx2) => (
                              <div
                                key={`${em}-${idx2}`}
                                className="w-12 h-12 flex items-center justify-center text-2xl border rounded-full"
                              >
                                {em}
                              </div>
                            ))}
                        </div>
                      )}
                      <div className="mt-4">
                        <p className="text-sm text-gray-600 mb-2">
                          Haz clic en un emoji para añadirlo:
                        </p>
                        <div className="flex gap-2 flex-wrap">
                          {defaultEmojis.map((emoji) => (
                            <button
                              key={emoji}
                              type="button"
                              onClick={() => handleEmojiClick(i, emoji)}
                              className="w-10 h-10 flex items-center justify-center text-2xl border rounded-full hover:bg-gray-100"
                            >
                              {emoji}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex justify-end mt-2">
                  <button
                    type="button"
                    onClick={() => handleRemoveQuestion(i)}
                    className="text-red-600 hover:text-red-800 text-sm"
                  >
                    Eliminar pregunta
                  </button>
                </div>
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddQuestion}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-[#FF3008] bg-[#FF3008]/10 hover:bg-[#FF3008]/20"
            >
              + Agregar Pregunta
            </button>
          </div>
        </div>

        <div className="pt-6">
          <button
            type="submit"
            className="w-full flex justify-center items-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#FF3008] hover:bg-[#FF3008]/90"
            disabled={isRegistering}
          >
            {isRegistering ? (
              <>
                Registrando...
                <div className="spinner ml-2"></div>
              </>
            ) : (
              "Registrar Cliente"
            )}
          </button>
        </div>
      </form>

      {/* Alerta para Free/Platinium si excede límite */}
      {showFreeLimitAlert && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-md p-6 max-w-sm w-full relative">
            <h2 className="text-xl font-bold text-red-600 mb-4">
              Límite de Plan Free/Platinium Alcanzado
            </h2>
            <p className="text-gray-700 mb-4">
              Solo puedes registrar hasta 1 sucursal y 4 preguntas con el plan
              seleccionado. ¿Deseas cambiar a un plan superior (Premium)?
            </p>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleCloseLimitAlert}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded hover:bg-gray-400"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Pago (para Platinium/Premium) */}
      {showPaymentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-md p-6 max-w-sm w-full relative">
            <h2 className="text-xl font-bold mb-4">Pago Requerido</h2>
            <p className="text-gray-700 mb-4">
              Para completar el registro en Plan {planType}, realiza tu pago en
              la pasarela externa. Luego confirma.
            </p>
            <button
              onClick={() =>
                window.open("https://payp.page.link/ewkNP", "_blank")
              }
              className="w-full py-2 px-4 bg-[#FF3008] text-white rounded hover:bg-[#FF3008]/90 mb-4"
            >
              Generar Pago
            </button>
            <button
              onClick={handlePaymentConfirmed}
              className="w-full py-2 px-4 bg-green-600 text-white rounded hover:bg-green-700"
            >
              He finalizado mi pago
            </button>
            <button
              onClick={closePaymentModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Modal para generar QR */}
      {showModalQR && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-lg w-full p-6 relative">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Generar QR de Sucursales
            </h2>
            {registeredLocations.length === 0 ? (
              <p className="text-sm text-gray-500">
                No hay sucursales registradas.
              </p>
            ) : (
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Sucursal
                  </label>
                  <select
                    value={selectedLocationId}
                    onChange={(e) => setSelectedLocationId(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                  >
                    <option value="">-- Seleccionar --</option>
                    {registeredLocations.map((loc) => (
                      <option key={loc.id} value={loc.id}>
                        {loc.name} (ID: {loc.id})
                      </option>
                    ))}
                  </select>
                </div>
                <button
                  onClick={handleGenerateQR}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#FF3008] hover:bg-[#FF3008]/90"
                >
                  Generar QR
                </button>
                {qrCodeBase64 && (
                  <div className="mt-4 space-y-4">
                    <div className="flex justify-center">
                      <img
                        src={`data:image/png;base64,${qrCodeBase64}`}
                        alt="QR Code"
                        className="w-40 h-40"
                      />
                    </div>
                    {uniqueUrl && (
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">URL:</span>{" "}
                        <a
                          href={uniqueUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-[#FF3008] hover:text-[#FF3008]/90"
                        >
                          {uniqueUrl}
                        </a>
                      </p>
                    )}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Descargar como
                      </label>
                      <select
                        onChange={handleDownloadSelect}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#FF3008] focus:ring-[#FF3008] sm:text-sm"
                      >
                        <option value="">-- Selecciona formato --</option>
                        <option value="jpg">JPG</option>
                        <option value="pdf">PDF</option>
                      </select>
                    </div>
                  </div>
                )}
              </div>
            )}
            <button
              onClick={closeModalQR}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}

      {/* Modal de Éxito final */}
      {showSuccessModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-md p-6 max-w-sm w-full relative">
            <h2 className="text-xl font-bold text-green-600 mb-4">¡Éxito!</h2>
            <p className="text-gray-700 mb-4">
              El cliente y sus sucursales se han registrado correctamente.
            </p>
            <button
              onClick={closeSuccessModal}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700"
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegisterClient;
