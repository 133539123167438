import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './ConfigurarProteinas.css';

const ConfigurarProteinas = () => {
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth()); // Mes actual (0-11)
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear()); // Año actual
  const [daysInMonth, setDaysInMonth] = useState([]); // Días del mes actual
  const [proteinOptions, setProteinOptions] = useState({}); // Proteínas por día
  const [selectedDay, setSelectedDay] = useState(null); // Día seleccionado para editar proteínas
  const [modalIsOpen, setModalIsOpen] = useState(false); // Estado del modal
  const [newProtein1, setNewProtein1] = useState(''); // Proteína 1 temporal
  const [newProtein2, setNewProtein2] = useState(''); // Proteína 2 temporal
  const [isLoading, setIsLoading] = useState(false); // Estado de carga
  const [error, setError] = useState(null); // Estado de errores
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

  useEffect(() => {
    const getDaysInMonth = (month, year) => {
      return new Date(year, month + 1, 0).getDate(); // Número de días en el mes
    };

    const days = getDaysInMonth(currentMonth, currentYear);
    const daysArray = Array.from({ length: days }, (_, index) => index + 1);
    setDaysInMonth(daysArray);

    fetchProteinData(); // Obtener las proteínas del backend según el mes y año
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMonth, currentYear]);

  const fetchProteinData = async () => {
    setIsLoading(true);
    setError(null);
    console.log(`Fetching proteins for month=${currentMonth + 1} and year=${currentYear}`);

    // Calcular el primer y último día del mes actual
    const startDateObj = new Date(currentYear, currentMonth, 1);
    const endDateObj = new Date(currentYear, currentMonth + 1, 0); // Último día del mes

    const startDate = startDateObj.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    const endDate = endDateObj.toISOString().split('T')[0]; // Formato YYYY-MM-DD

    try {
      const response = await fetch(`${backendUrl}/api/proteins?startDate=${startDate}&endDate=${endDate}`);
      if (response.ok) {
        const data = await response.json();
        console.log('Protein data retrieved:', data);
        setProteinOptions(data); // Guardar las proteínas por día
      } else {
        const errorData = await response.json();
        console.error('Error al obtener los datos de proteínas:', errorData.message);
        setError(errorData.message || 'Error al obtener los datos de proteínas.');
      }
    } catch (error) {
      console.error('Error al conectarse con el servidor:', error);
      setError('Error al conectarse con el servidor.');
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = (day) => {
    const proteinsForDay = proteinOptions[day] || {};
    setSelectedDay(day);
    setNewProtein1(proteinsForDay.protein1 || '');
    setNewProtein2(proteinsForDay.protein2 || '');
    setModalIsOpen(true);
  };

  const handleSaveProteins = async () => {
    if (!selectedDay) {
      setError('No se ha seleccionado un día.');
      return;
    }

    if (!newProtein1.trim() || !newProtein2.trim()) {
      setError('Ambas proteínas son requeridas.');
      return;
    }

    setIsLoading(true);
    setError(null);

    // Actualizar el estado local optimistamente
    setProteinOptions((prev) => ({
      ...prev,
      [selectedDay]: {
        protein1: newProtein1.trim(),
        protein2: newProtein2.trim(),
      },
    }));

    console.log('Saving proteins:', {
      day: selectedDay,
      month: currentMonth + 1,
      year: currentYear,
      protein1: newProtein1.trim(),
      protein2: newProtein2.trim(),
    });

    // Guardar en el backend
    try {
      const response = await fetch(`${backendUrl}/api/proteins/save`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          day: selectedDay,
          month: currentMonth + 1,
          year: currentYear,
          protein1: newProtein1.trim(),
          protein2: newProtein2.trim(),
        }),
      });

      if (response.ok) {
        console.log('Proteínas guardadas exitosamente.');
        await fetchProteinData(); // Recargar los datos del servidor
      } else {
        const errorData = await response.json();
        console.error('Error al guardar las proteínas:', errorData.message);
        setError(errorData.message || 'Error al guardar las proteínas.');
      }
    } catch (error) {
      console.error('Error al conectarse con el servidor:', error);
      setError('Error al conectarse con el servidor.');
    } finally {
      setIsLoading(false);
      setModalIsOpen(false);
    }
  };

  const handleMonthChange = (direction) => {
    if (direction === 'prev') {
      if (currentMonth === 0) {
        setCurrentMonth(11);
        setCurrentYear(currentYear - 1);
      } else {
        setCurrentMonth(currentMonth - 1);
      }
    } else if (direction === 'next') {
      if (currentMonth === 11) {
        setCurrentMonth(0);
        setCurrentYear(currentYear + 1);
      } else {
        setCurrentMonth(currentMonth + 1);
      }
    }
  };

  const renderCalendar = () => {
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();
    const emptyDays = Array(firstDayOfMonth).fill(null);
    const daysToRender = [...emptyDays, ...daysInMonth];

    return daysToRender.map((day, index) => {
      const proteinConfig = proteinOptions[day];
      const isConfigured = Boolean(proteinConfig);

      return (
        <div key={index} className="calendar-day">
          {day ? (
            <div
              className={`day-content ${isConfigured ? 'configured' : ''}`}
              onClick={!isConfigured ? () => openModal(day) : null}
            >
              <span>{day}</span>
              {isConfigured ? (
                <span className="check-icon" title="Proteínas configuradas">✔</span>
              ) : (
                <span className="add-protein">Agregar</span>
              )}
            </div>
          ) : (
            <div className="empty-day"></div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="configurar-proteinas-container">
      <h2 className="text-center">Configurar Proteínas Mensuales</h2>

      <div className="month-navigation">
        <button onClick={() => handleMonthChange('prev')} disabled={isLoading}>
          Anterior
        </button>
        <span>{`${new Date(currentYear, currentMonth).toLocaleString('default', { month: 'long' })} ${currentYear}`}</span>
        <button onClick={() => handleMonthChange('next')} disabled={isLoading}>
          Siguiente
        </button>
      </div>

      {isLoading && <p>Cargando datos...</p>}
      {error && <p className="error-message">{error}</p>}

      <div className="calendar-grid">
        {/* Renderizar encabezados de los días de la semana */}
        <div className="calendar-day header">Dom</div>
        <div className="calendar-day header">Lun</div>
        <div className="calendar-day header">Mar</div>
        <div className="calendar-day header">Mié</div>
        <div className="calendar-day header">Jue</div>
        <div className="calendar-day header">Vie</div>
        <div className="calendar-day header">Sáb</div>
        {renderCalendar()}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        ariaHideApp={false}
        className="protein-modal"
        overlayClassName="protein-modal-overlay"
      >
        <h4>Seleccionar proteínas para el día {selectedDay}</h4>
        <div className="form-group">
          <label htmlFor="protein1">Proteína 1:</label>
          <input
            id="protein1"
            type="text"
            value={newProtein1}
            onChange={(e) => setNewProtein1(e.target.value)}
            className="form-control"
            placeholder="Ej: Pollo"
          />
        </div>
        <div className="form-group">
          <label htmlFor="protein2">Proteína 2:</label>
          <input
            id="protein2"
            type="text"
            value={newProtein2}
            onChange={(e) => setNewProtein2(e.target.value)}
            className="form-control"
            placeholder="Ej: Carne"
          />
        </div>
        <div className="modal-actions">
          <button className="btn btn-secondary" onClick={() => setModalIsOpen(false)} disabled={isLoading}>
            Cancelar
          </button>
          <button className="btn btn-primary" onClick={handleSaveProteins} disabled={isLoading}>
            Guardar Proteínas
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ConfigurarProteinas;
