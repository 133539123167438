import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../auth/AuthContext';
import Modal from 'react-modal';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './ProgramarComidas.css';
import pinIcon from './pin.png'; // Asegúrate de tener este archivo en la ruta correcta

const customIcon = new L.Icon({
  iconUrl: pinIcon,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const ProgramarComidas = () => {
  const { user, addresses } = useContext(AuthContext);
  const navigate = useNavigate();

  const [weeklyMenu, setWeeklyMenu] = useState({
    lunes: '',
    martes: '',
    miercoles: '',
    jueves: '',
    viernes: '',
    sabado: '',
    domingo: '',
  });

  const [weeklyAddresses, setWeeklyAddresses] = useState({
    lunes: null,
    martes: null,
    miercoles: null,
    jueves: null,
    viernes: null,
    sabado: null,
    domingo: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [error, setError] = useState('');
  const [addressSelectionOpen, setAddressSelectionOpen] = useState(false);
  const [addressChangeModalOpen, setAddressChangeModalOpen] = useState(false);
  const [selectedDayForAddressChange, setSelectedDayForAddressChange] = useState(null);
  const [newAddressForDay, setNewAddressForDay] = useState('');

  const [availableProteins, setAvailableProteins] = useState({});
  const [hasScheduledMeals, setHasScheduledMeals] = useState(false); // Nuevo estado para indicar si hay comidas programadas

  // **Nuevos estados agregados para manejar los saldos**
  const [userBalance, setUserBalance] = useState({ saldo: 0, saldo_comida: 0 });
  const [balancesAfter, setBalancesAfter] = useState({ saldo: 0, saldo_comida: 0 });

  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
  const mapRef = useRef();

  // Función para obtener las fechas de la próxima semana, memorizada para evitar recreaciones
  const getNextWeekDates = useCallback(() => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Domingo) - 6 (Sábado)
    const daysUntilNextMonday = (8 - dayOfWeek) % 7 || 7; // Calcula los días hasta el próximo lunes
    const startOfNextWeek = new Date(today);
    startOfNextWeek.setDate(today.getDate() + daysUntilNextMonday);
    startOfNextWeek.setHours(0, 0, 0, 0);

    const daysOfWeek = ['lunes', 'martes', 'miercoles', 'jueves', 'viernes', 'sabado', 'domingo'];

    return daysOfWeek.map((day, index) => {
      const date = new Date(startOfNextWeek);
      date.setDate(startOfNextWeek.getDate() + index);
      return { day, date };
    });
  }, []);

  // Memoizar las fechas de la próxima semana para que no cambien en cada renderizado
  const nextWeekDates = useMemo(() => getNextWeekDates(), [getNextWeekDates]);

  // Efecto para seleccionar automáticamente la dirección principal si está disponible
  useEffect(() => {
    if (!weeklyAddresses.lunes && addresses.length > 0) {
      const primary = addresses.find(addr => addr.type === 'primary');
      if (primary) {
        const initialWeeklyAddresses = {};
        nextWeekDates.forEach(({ day }) => {
          initialWeeklyAddresses[day] = primary;
        });
        setWeeklyAddresses(initialWeeklyAddresses);
      } else {
        const initialWeeklyAddresses = {};
        nextWeekDates.forEach(({ day }) => {
          initialWeeklyAddresses[day] = addresses[0];
        });
        setWeeklyAddresses(initialWeeklyAddresses);
      }
    }
  }, [addresses, nextWeekDates, weeklyAddresses.lunes]);

  // Función para obtener las proteínas disponibles de la semana entrante desde el backend
  const fetchProteinsForDay = useCallback(async (day, month, year) => {
    try {
      console.log(`Fetching proteins for day=${day}, month=${month}, year=${year}`);
      const response = await fetch(`${backendUrl}/api/proteinsForDay?day=${day}&month=${month}&year=${year}`);
      if (response.ok) {
        const data = await response.json();
        console.log(`Protein data retrieved for ${day}/${month}/${year}:`, data);
        return data;
      } else if (response.status === 404) {
        throw new Error(`No se encontraron proteínas para ${day} (${new Date(year, month - 1, day).toLocaleDateString()})`);
      } else {
        throw new Error(`Error al obtener proteínas para ${day} (${new Date(year, month - 1, day).toLocaleDateString()})`);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, [backendUrl]);

  const fetchProteinsForWeek = useCallback(async () => {
    if (!addresses.length) return;

    const proteinsData = {};
    let errorMessages = '';

    try {
      for (const { day, date } of nextWeekDates) {
        const dayNum = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        try {
          const data = await fetchProteinsForDay(dayNum, month, year);
          proteinsData[day] = data;
        } catch (err) {
          proteinsData[day] = { protein1: '', protein2: '' };
          errorMessages += `${err.message}\n`;
        }
      }
      setAvailableProteins(proteinsData);
      if (errorMessages) {
        setError(errorMessages.trim());
      } else {
        setError('');
      }
    } catch (err) {
      console.error('Error al obtener las proteínas:', err);
      setError('Error al obtener las proteínas. Inténtalo nuevamente.');
    }
  }, [fetchProteinsForDay, nextWeekDates, addresses.length]);

  // **Función para obtener el saldo del usuario**
  const fetchUserBalance = useCallback(async () => {
    try {
        const response = await fetch(`${backendUrl}/api/users/${user.id}/balance`);
        if (response.ok) {
            const data = await response.json();
            console.log('Saldo del usuario recibido:', data); // Log para verificar el saldo recibido
            setUserBalance({
                saldo: parseFloat(data.saldo),
                saldo_comida: parseFloat(data.saldo_comida),
            });
        } else {
            throw new Error('Error al obtener el saldo del usuario.');
        }
    } catch (err) {
        console.error('Error al obtener el saldo del usuario:', err);
        setError('Error al obtener el saldo del usuario. Inténtalo nuevamente.');
    }
}, [backendUrl, user.id]);

  // Efecto para obtener las proteínas disponibles y el saldo del usuario cuando se actualizan las direcciones asignadas
  useEffect(() => {
    fetchProteinsForWeek();
    fetchUserBalance(); // **Llamada para obtener el saldo del usuario**
  }, [fetchProteinsForWeek, fetchUserBalance]);

  // Nuevo useEffect para verificar si ya hay comidas programadas
  useEffect(() => {
    const checkScheduledMeals = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/scheduled_meals/check`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          if (data.scheduled) {
            setHasScheduledMeals(true);
            alert('Ya has programado tus comidas para la próxima semana.');
          } else {
            setHasScheduledMeals(false);
          }
        } else {
          let errorData;
          const contentType = response.headers.get('Content-Type');
          if (contentType && contentType.includes('application/json')) {
            errorData = await response.json();
          } else {
            errorData = { message: response.statusText || 'Error desconocido.' };
          }
          setError(errorData.message || 'Error al verificar comidas programadas.');
          console.error('Error al verificar comidas programadas:', errorData.message || response.statusText);
        }
      } catch (err) {
        console.error('Error al verificar comidas programadas:', err);
        setError('Error al verificar comidas programadas.');
      }
    };

    checkScheduledMeals();
  }, [backendUrl]);

  // Manejar cambio de proteína por día
  const handleProteinChange = (day, protein) => {
    setWeeklyMenu((prevMenu) => ({
      ...prevMenu,
      [day]: protein,
    }));
  };

  // **Efecto para recalcular los saldos después de que el usuario selecciona las comidas**
  useEffect(() => {
    console.log('Recalculando balances...'); // Log antes de recalcular
    recalculateBalances();
  }, [weeklyMenu]);

  // **Función para recalcular los saldos**
  const recalculateBalances = () => {
    const totalMeals = Object.keys(weeklyMenu).filter(day => weeklyMenu[day] !== '').length;

    // Asumimos un costo fijo por comida. Por ejemplo, $3.50
    const costPerMeal = 3.50;
    const totalCost = totalMeals * costPerMeal;

    console.log('Total de comidas seleccionadas:', totalMeals);
    console.log('Costo total calculado:', totalCost);
    console.log('Saldo actual:', userBalance.saldo);

    const newSaldo = userBalance.saldo - totalCost;
    const newSaldoComida = userBalance.saldo_comida - totalMeals;

    console.log('Nuevo saldo monetario:', newSaldo);
    console.log('Nuevo saldo en comidas:', newSaldoComida);

    setBalancesAfter({
      saldo: newSaldo,
      saldo_comida: newSaldoComida,
    });
  };

  // Abrir el modal de selección de dirección
  const handleChangeAddress = () => {
    setAddressSelectionOpen(true);
  };

  // Manejar selección de dirección dentro del modal
  const handleAddressSelection = (address) => {
    const initialWeeklyAddresses = {};
    nextWeekDates.forEach(({ day }) => {
      initialWeeklyAddresses[day] = address;
    });
    setWeeklyAddresses(initialWeeklyAddresses);
    setAddressSelectionOpen(false);
    setWeeklyMenu({
      lunes: '',
      martes: '',
      miercoles: '',
      jueves: '',
      viernes: '',
      sabado: '',
      domingo: '',
    });
    alert('Dirección actualizada exitosamente. Los envíos para esta semana han sido restringidos hasta que confirmes nuevamente.');
  };

  // Abrir el modal para cambiar la dirección de un día específico
  const handleOpenAddressChangeModal = (day) => {
    setSelectedDayForAddressChange(day);
    setNewAddressForDay(weeklyAddresses[day]?.address || '');
    setAddressChangeModalOpen(true);
  };

  // Manejar la confirmación del cambio de dirección para un día específico
  const handleConfirmAddressChange = () => {
    const day = selectedDayForAddressChange;
    const newAddressObj = addresses.find(addr => addr.address === newAddressForDay);
    if (newAddressObj) {
      setWeeklyAddresses((prevAddresses) => ({
        ...prevAddresses,
        [day]: newAddressObj,
      }));
      setAddressChangeModalOpen(false);
    } else {
      alert('Seleccione una dirección válida.');
    }
  };

  // Enviar el menú semanal al backend
  const handleSubmit = async () => {
    if (hasScheduledMeals) {
      alert('Ya has programado tus comidas para esta semana.');
      return;
    }

    const selectedProteins = Object.keys(weeklyMenu).filter((day) => weeklyMenu[day] !== '');
    if (selectedProteins.length < 7) {
      alert('Por favor, selecciona una proteína para todos los días de la semana.');
      return;
    }

    if (!addresses.length) {
      alert('Por favor, agrega al menos una dirección antes de programar las comidas.');
      return;
    }

    // **Validar que el usuario tiene suficiente saldo**
    if (balancesAfter.saldo < 0 || balancesAfter.saldo_comida < 0) {
      let insufficientBalanceMessage = 'No tienes suficiente saldo para programar estas comidas.\n';
      if (balancesAfter.saldo < 0) {
        insufficientBalanceMessage += 'Saldo monetario insuficiente.\n';
      }
      if (balancesAfter.saldo_comida < 0) {
        insufficientBalanceMessage += 'Saldo en comidas insuficiente.\n';
      }
      alert(insufficientBalanceMessage.trim());
      return;
    }

    setIsSubmitting(true);
    setError('');

    const requestBody = {
      userId: user.id, // Incluye el userId en el cuerpo de la solicitud
      weeklyMenu: weeklyMenu, // { lunes: 'Pollo', martes: 'Carne', ... }
      weeklyAddresses: Object.fromEntries(
        Object.entries(weeklyAddresses).map(([day, addr]) => [day, addr.address])
      ),
    };

    try {
      const response = await fetch(`${backendUrl}/api/scheduled_meals`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setSuccessMessage(true);
        setWeeklyMenu({
          lunes: '',
          martes: '',
          miercoles: '',
          jueves: '',
          viernes: '',
          sabado: '',
          domingo: '',
        });
        setHasScheduledMeals(true); // Actualizar el estado
        setError('');

        // **Actualizar el saldo del usuario después de programar las comidas**
        setUserBalance({
          saldo: balancesAfter.saldo,
          saldo_comida: balancesAfter.saldo_comida,
        });

      } else {
        let errorData;
        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
          errorData = await response.json();
        } else {
          errorData = { message: response.statusText || 'Error desconocido.' };
        }
        setError(errorData.message || 'Error al programar las comidas.');
      }
    } catch (err) {
      console.error('Error al programar las comidas:', err);
      setError('Error al programar las comidas. Inténtalo nuevamente.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Navegar de vuelta a la página principal
  const navigateToHome = () => {
    navigate('/home-foodlove');
  };

  // Definir la Dirección de Entrega General
  const generalAddress = useMemo(() => {
    return addresses.find(addr => addr.type === 'primary') || addresses[0];
  }, [addresses]);

  // Identificar las Direcciones de Entrega Específicas por Día
  const specificDayAddresses = useMemo(() => {
    return nextWeekDates.filter(({ day }) => {
      const addr = weeklyAddresses[day];
      return addr && addr.address !== generalAddress.address;
    }).map(({ day }) => ({
      day,
      address: weeklyAddresses[day],
    }));
  }, [nextWeekDates, weeklyAddresses, generalAddress]);

  // Crear una Lista Única de Direcciones para el Mapa
  const uniqueMapAddresses = useMemo(() => {
    const addressMap = new Map();

    // Agregar la dirección general
    if (generalAddress && generalAddress.latitude && generalAddress.longitude) {
      addressMap.set(generalAddress.address, {
        address: generalAddress,
        days: ['General'],
      });
    }

    // Agregar las direcciones específicas por día
    specificDayAddresses.forEach(({ day, address }) => {
      if (address.latitude && address.longitude) { // Asegurar coordenadas válidas
        if (addressMap.has(address.address)) {
          addressMap.get(address.address).days.push(day.charAt(0).toUpperCase() + day.slice(1));
        } else {
          addressMap.set(address.address, {
            address: address,
            days: [day.charAt(0).toUpperCase() + day.slice(1)],
          });
        }
      } else {
        console.warn(`La dirección para ${day} no tiene coordenadas válidas y no se mostrará en el mapa.`);
      }
    });

    return Array.from(addressMap.values());
  }, [generalAddress, specificDayAddresses]);

  // Ajustar la Vista del Mapa para Incluir Todos los Marcadores
  useEffect(() => {
    if (mapRef.current && uniqueMapAddresses.length > 0) {
      const bounds = uniqueMapAddresses.map(({ address }) => [address.latitude, address.longitude]);

      if (bounds.length === 1) {
        mapRef.current.setView(bounds[0], 13);
      } else {
        mapRef.current.fitBounds(bounds, { padding: [50, 50] });
      }
    }
  }, [uniqueMapAddresses]);

  return (
    <div className="programar-comidas-container p-6 max-w-4xl mx-auto bg-white rounded-lg shadow-lg">
      <h2 className="text-center text-yellow-500 mb-6 text-2xl font-semibold">Programar Comidas Semanales</h2>

      {error && <div className="error-message text-red-500 mb-4 whitespace-pre-line">{error}</div>}

      {hasScheduledMeals && (
        <div className="alert alert-info mb-6 p-4 bg-blue-100 text-blue-700 rounded">
          Ya has programado tus comidas para la próxima semana.
        </div>
      )}

      {/* **Sección para mostrar los saldos del usuario** */}
      <div className="balance-info mb-6 p-6 border rounded-md bg-white shadow-md">
        <h4 className="text-lg font-bold mb-4 text-gray-800">Información de Saldo:</h4>
        <p className="text-gray-700">Saldo Monetario Actual: ${userBalance.saldo.toFixed(2)}</p>
        <p className="text-gray-700">Saldo en Comidas Actual: {userBalance.saldo_comida}</p>
        <p className="text-gray-700">Saldo Monetario Después de Programar: ${balancesAfter.saldo.toFixed(2)}</p>
        <p className="text-gray-700">Saldo en Comidas Después de Programar: {balancesAfter.saldo_comida}</p>
      </div>

      {/* Sección para mostrar la dirección de entrega */}
      <div className="delivery-address-section mb-6 p-6 border rounded-md bg-white shadow-md">
        <h4 className="text-lg font-bold mb-4 text-gray-800">Dirección de Entrega:</h4>
        <p className="mb-4 text-gray-700">
          {weeklyAddresses.lunes ? (
            <>
              <span>{weeklyAddresses.lunes.address}</span><br />
              <span className="text-gray-500">{weeklyAddresses.lunes.description}</span>
            </>
          ) : 'Dirección no disponible'}
        </p>
        <button onClick={handleChangeAddress} className="btn btn-warning px-6 py-3 rounded-md text-white hover:bg-orange-600 transition-colors">
          Cambiar Dirección
        </button>
      </div>

      {/* Modal para seleccionar la dirección de entrega */}
      <Modal 
        isOpen={addressSelectionOpen} 
        onRequestClose={() => setAddressSelectionOpen(false)} 
        className="modal-content"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="relative bg-white p-8 rounded-md shadow-md w-full max-w-md">
          <button 
            onClick={() => setAddressSelectionOpen(false)} 
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Cerrar Modal"
          >
            &times;
          </button>
          <h2 className="text-xl mb-4 text-center font-bold text-gray-800">Selecciona una Dirección</h2>
          <ul>
            {addresses.map((addr, index) => (
              <li 
                key={index} 
                className="mb-3 flex justify-between items-center bg-gray-100 p-4 rounded-md cursor-pointer hover:bg-gray-200 transition-colors"
                onClick={() => handleAddressSelection(addr)}
              >
                <div>
                  <p className="font-semibold">{addr.type === 'primary' ? 'Dirección Principal' : `Secundaria ${index + 1}`}</p>
                  <p className="text-gray-700">{addr.address}</p>
                  <p className="text-sm text-gray-500">{addr.description}</p>
                </div>
                {weeklyAddresses.lunes && weeklyAddresses.lunes.address === addr.address && (
                  <span className="text-green-500 text-xl">✓</span>
                )}
              </li>
            ))}
          </ul>
          <button className="btn btn-primary mt-6 w-full px-6 py-3 rounded-md text-white hover:bg-blue-600 transition-colors" onClick={() => setAddressSelectionOpen(false)}>
            Seleccionar
          </button>
        </div>
      </Modal>

      {/* Modal para cambiar la dirección de un día específico */}
      <Modal 
        isOpen={addressChangeModalOpen} 
        onRequestClose={() => setAddressChangeModalOpen(false)} 
        className="modal-content"
        overlayClassName="modal-overlay"
        ariaHideApp={false}
      >
        <div className="relative bg-white p-8 rounded-md shadow-md w-full max-w-md">
          <button 
            onClick={() => setAddressChangeModalOpen(false)} 
            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 focus:outline-none"
            aria-label="Cerrar Modal"
          >
            &times;
          </button>
          <h2 className="text-xl mb-4 text-center font-bold text-gray-800">Cambiar Dirección para {selectedDayForAddressChange}</h2>
          <select
            value={newAddressForDay}
            onChange={(e) => setNewAddressForDay(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 mb-4"
          >
            <option value="">Selecciona una dirección</option>
            {addresses.map((addr, index) => (
              <option key={index} value={addr.address}>
                {addr.type === 'primary' ? 'Dirección Principal' : `Secundaria ${index + 1}`}
              </option>
            ))}
          </select>
          <button 
            className="btn btn-primary w-full py-3 rounded-md text-white hover:bg-blue-600 transition-colors" 
            onClick={handleConfirmAddressChange}
            disabled={!newAddressForDay}
          >
            Confirmar Cambio
          </button>
        </div>
      </Modal>

      {/* Sección del menú semanal */}
      <div className="weekly-menu-section mb-6">
        <h4 className="text-lg font-bold mb-4 text-gray-800">Selecciona la proteína para cada día de la semana (Semana Entrante):</h4>
        <div className="weekly-menu-grid grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {nextWeekDates.map(({ day, date }) => (
            <div key={day} className="day-selection">
              <div className="flex justify-between items-center mb-2">
                <label className="day-label capitalize block text-gray-700 font-medium">{day} ({date.toLocaleDateString()})</label>
                <button 
                  className="btn btn-secondary px-3 py-1 rounded-md text-white hover:bg-gray-600 transition-colors"
                  onClick={() => handleOpenAddressChangeModal(day)}
                >
                  Cambiar Dirección
                </button>
              </div>
              <select
                value={weeklyMenu[day] || ''}
                onChange={(e) => handleProteinChange(day, e.target.value)}
                disabled={isSubmitting || !availableProteins[day] || (availableProteins[day].protein1 === '' && availableProteins[day].protein2 === '')}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              >
                <option value="">Selecciona la proteína</option>
                {availableProteins[day] && availableProteins[day].protein1 && (
                  <option value={availableProteins[day].protein1}>{availableProteins[day].protein1}</option>
                )}
                {availableProteins[day] && availableProteins[day].protein2 && (
                  <option value={availableProteins[day].protein2}>{availableProteins[day].protein2}</option>
                )}
              </select>
              {/* Mostrar la dirección asignada para el día con descripción */}
              <p className="mt-2 text-sm text-gray-600">
                <strong>Dirección:</strong> {weeklyAddresses[day] ? weeklyAddresses[day].address : 'Dirección Principal'}<br />
                {weeklyAddresses[day] && <span className="text-gray-500">{weeklyAddresses[day].description}</span>}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Resumen Consolidado de Direcciones por Día */}
      <div className="address-summary-section mb-6 p-6 border rounded-md bg-white shadow-md">
        <h4 className="text-lg font-bold mb-4 text-gray-800">Resumen de Direcciones de Entrega:</h4>
        <ul>
          {nextWeekDates.map(({ day, date }) => (
            <li key={day} className="mb-2 flex flex-col sm:flex-row justify-between items-start sm:items-center">
              <span className="capitalize text-gray-700 font-medium">{day} ({date.toLocaleDateString()})</span>
              <span className="text-gray-600">
                {weeklyAddresses[day] ? (
                  <>
                    <span>{weeklyAddresses[day].address}</span><br />
                    <span className="text-gray-500">{weeklyAddresses[day].description}</span>
                  </>
                ) : 'Dirección Principal'}
              </span>
            </li>
          ))}
        </ul>
      </div>

      {/* Mapa con la ubicación de entrega */}
      <div className="map-section mb-6">
        <h4 className="text-lg font-bold mb-4 text-gray-800">Ubicación de Entrega:</h4>
        {uniqueMapAddresses.length > 0 ? (
          <MapContainer 
            center={[
              uniqueMapAddresses.length > 0 ? uniqueMapAddresses[0].address.latitude : 0, 
              uniqueMapAddresses.length > 0 ? uniqueMapAddresses[0].address.longitude : 0
            ]} 
            zoom={13} 
            scrollWheelZoom={false} 
            style={{ height: '400px', width: '100%' }} // Aseguramos que el mapa tenga dimensiones
            className="map-container rounded-md shadow-md"
            whenCreated={(mapInstance) => { mapRef.current = mapInstance; }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution="&copy; OpenStreetMap contributors"
            />
            {uniqueMapAddresses.map(({ address, days }, index) => (
              <Marker 
                key={index}
                position={[address.latitude, address.longitude]} 
                icon={customIcon}
              >
                <Popup>
                  <strong>
                    {days.includes('General') 
                      ? 'Dirección de Entrega General' 
                      : `Dirección de Entrega para ${days.join(', ')}`
                    }
                  </strong><br />
                  {address.address}<br />
                  {address.description && <span className="text-gray-500">{address.description}</span>}
                </Popup>
              </Marker>
            ))}
          </MapContainer>
        ) : (
          <p className="text-gray-700">No hay direcciones seleccionadas.</p>
        )}
      </div>

      {/* Nueva Sección: Detalles de Direcciones de Entrega General y Específicas por Día */}
      <div className="additional-addresses-section mb-6 p-6 border rounded-md bg-white shadow-md">
        <h4 className="text-lg font-bold mb-4 text-gray-800">Detalles de Direcciones de Entrega:</h4>
        {/* Dirección de Entrega General */}
        <div className="general-delivery-address mb-4">
          <h5 className="text-md font-semibold text-gray-800">Dirección de entrega general:</h5>
          <p className="text-gray-700">
            {generalAddress ? (
              <>
                <span>{generalAddress.address}</span><br />
                <span className="text-gray-500">{generalAddress.description}</span>
              </>
            ) : 'No hay dirección general disponible'}
          </p>
        </div>
        {/* Direcciones de Entrega Específicas por Día */}
        {specificDayAddresses.length > 0 && (
          <div className="specific-day-delivery-addresses">
            <h5 className="text-md font-semibold text-gray-800">Direcciones de entrega por día:</h5>
            <ul className="mt-2">
              {specificDayAddresses.map(({ day, address }) => (
                <li key={day} className="mb-2 text-gray-700">
                  <strong>Dirección de entrega {day.charAt(0).toUpperCase() + day.slice(1)}:</strong><br />
                  {address.address}<br />
                  <span className="text-gray-500">{address.description}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* Fin de la Nueva Sección */}

      {/* Botón para programar las comidas */}
      <button
        className="btn btn-primary w-full py-3 rounded-md text-white hover:bg-blue-600 transition-colors"
        onClick={handleSubmit}
        disabled={isSubmitting || !addresses.length || hasScheduledMeals} // Deshabilitar si ya está programado
      >
        {isSubmitting ? 'Programando...' : 'Programar Comidas'}
      </button>

      {/* Mensaje de éxito después de programar las comidas */}
      {successMessage && (
        <div className="success-modal fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
          <div className="modal-content bg-white p-8 rounded-md shadow-md text-center">
            <h5 className="text-center text-green-600 text-xl mb-4">¡Comidas Programadas Exitosamente!</h5>
            <p className="text-center mb-4">Tus comidas han sido programadas para esta semana.</p>
            <div className="modal-footer mt-4">
              <button className="btn btn-primary px-6 py-3 rounded-md text-white hover:bg-blue-600 transition-colors" onClick={() => setSuccessMessage(false)}>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramarComidas;
