// src/components/foodlove/Notification.js
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';

const Notification = {
  success: (message) => {
    toast.success(message, {
      position: 'top-right',
      autoClose: 3000,
    });
  },
  error: (message) => {
    toast.error(message, {
      position: 'top-right',
      autoClose: 5000,
    });
  },
  info: (message) => {
    toast.info(message, {
      position: 'top-right',
      autoClose: 3000,
    });
  },
  warning: (message) => {
    toast.warn(message, {
      position: 'top-right',
      autoClose: 3000,
    });
  },
};

export default Notification;
