// src/components/foodlove/SaldosFoodLove.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom'; // Importar useNavigate para la redirección
import './SaldosFoodLove.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const SaldosFoodLove = () => {
  const [users, setUsers] = useState([]);
  const [modifiedUsers, setModifiedUsers] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const navigate = useNavigate(); // Inicializar useNavigate

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${backendUrl}/api/users_saldos`);
        setUsers(Array.isArray(response.data) ? response.data : []);
      } catch (err) {
        console.error("Error durante la carga de usuarios:", err);
        setError('Error al cargar los datos de los usuarios');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Función para manejar cambios en el saldo
  const handleSaldoChange = (id, newSaldo) => {
    // Elimina ceros iniciales si existen
    const formattedSaldo = newSaldo.replace(/^0+/, '') || '0';

    setUsers(users.map(user => 
      user.id === id ? { ...user, saldo: formattedSaldo } : user
    ));
    setModifiedUsers(prevState => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        saldo: parseFloat(formattedSaldo) || 0,
      },
    }));
  };

  // Función para manejar cambios en el saldo_comida
  const handleSaldoComidaChange = (id, newSaldoComida) => {
    // Elimina ceros iniciales si existen
    const formattedSaldoComida = newSaldoComida.replace(/^0+/, '') || '0';

    setUsers(users.map(user => 
      user.id === id ? { ...user, saldo_comida: formattedSaldoComida } : user
    ));
    setModifiedUsers(prevState => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        saldo_comida: parseFloat(formattedSaldoComida) || 0,
      },
    }));
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const usersToUpdate = Object.keys(modifiedUsers).map(id => {
        const userModifications = { id: parseInt(id) };
        if (modifiedUsers[id].saldo !== undefined) {
          userModifications.saldo = modifiedUsers[id].saldo;
        }
        if (modifiedUsers[id].saldo_comida !== undefined) {
          userModifications.saldo_comida = modifiedUsers[id].saldo_comida;
        }
        return userModifications;
      });

      await axios.post(`${backendUrl}/api/update_saldos`, { users: usersToUpdate });
      alert('Saldos actualizados exitosamente');
      setModifiedUsers({}); // Resetea los usuarios modificados
    } catch (err) {
      console.error("Error al actualizar los saldos:", err);
      setError('Error al actualizar los saldos');
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadExcel = async () => {
    try {
      const response = await axios.get(`${backendUrl}/api/download_balances`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'balances_usuarios.xlsx');
    } catch (err) {
      console.error("Error al descargar el archivo Excel:", err);
      setError('Error al descargar el archivo Excel');
    }
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0];
    const maxSizeInMB = 50; // Limite máximo de tamaño en MB
    if (selectedFile.size > maxSizeInMB * 1024 * 1024) {
      alert(`El archivo es demasiado grande. El tamaño máximo permitido es ${maxSizeInMB} MB.`);
      setFile(null);
      return;
    }
    setFile(selectedFile);
  };

  const handleUploadExcel = async () => {
    if (!file) {
      alert('Por favor, seleccione un archivo para cargar');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${backendUrl}/api/upload_balances`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      });
      alert('Archivo cargado exitosamente');
      window.location.reload(); // Refrescar la página para cargar los nuevos saldos
    } catch (err) {
      if (err.response && err.response.status === 413) {
        alert('El archivo es demasiado grande para ser procesado. Intenta con un archivo más pequeño.');
      } else {
        console.error("Error al cargar el archivo Excel:", err);
        setError('Error al cargar el archivo Excel');
      }
    }
  };

  return (
    <div className="saldos-food-love">
      <h1>Administración de Saldos</h1>
      {loading && <p>Cargando...</p>}
      {error && <p className="error-message">{error}</p>}
      {uploadProgress > 0 && <p>Cargando archivo: {uploadProgress}%</p>}

      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre o Razón Social</th>
            <th>DNI/RUC</th>
            <th>Email</th>
            <th>Saldo (USD)</th>
            <th>Saldo Comidas </th> {/* Nueva columna */}
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.name}</td>
              <td>{user.identification_id}</td>
              <td>{user.email}</td>
              <td>
                <input 
                  type="text" 
                  value={user.saldo} 
                  onChange={(e) => handleSaldoChange(user.id, e.target.value)} 
                  onFocus={(e) => e.target.value === '0' && setUsers(users.map(u => u.id === user.id ? { ...u, saldo: '' } : u))}
                  onBlur={(e) => handleSaldoChange(user.id, e.target.value)}
                />
              </td>
              <td>
                <input 
                  type="text" 
                  value={user.saldo_comida || '0'} 
                  onChange={(e) => handleSaldoComidaChange(user.id, e.target.value)} 
                  onFocus={(e) => e.target.value === '0' && setUsers(users.map(u => u.id === user.id ? { ...u, saldo_comida: '' } : u))}
                  onBlur={(e) => handleSaldoComidaChange(user.id, e.target.value)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="buttons-section">
        <button 
          onClick={handleSaveChanges} 
          disabled={Object.keys(modifiedUsers).length === 0}
          className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
        >
          Guardar Cambios
        </button>
        <button 
          onClick={handleDownloadExcel}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Descargar Excel
        </button>
        <input type="file" onChange={handleFileUpload} />
        <button 
          onClick={handleUploadExcel}
          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600"
        >
          Cargar Excel
        </button>
        {/* Nuevo Botón Añadido */}
        <button
          onClick={() => navigate('/home-foodlove')}
          className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600"
        >
          Regresar a Home
        </button>
      </div>
    </div>
  );
};

export default SaldosFoodLove;
