// src/components/Configuraciones.jsx

import React, { useState, useContext } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './Configuraciones.css';
import pinIcon from './pin.png';
import { AuthContext } from '../../auth/AuthContext';

const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

// Icono personalizado para el marcador
const customIcon = new L.Icon({
  iconUrl: pinIcon,
  iconSize: [40, 40],
  iconAnchor: [20, 40],
  popupAnchor: [0, -40],
});

const Configuraciones = () => {
  const { user, isLoading: authLoading } = useContext(AuthContext); // Acceder al usuario desde el contexto
  const [mapLocation, setMapLocation] = useState([-0.1807, -78.4678]); // Quito, Ecuador por defecto
  const [description, setDescription] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('Dirección no disponible');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [type, setType] = useState('primary');

  // Componente para manejar los eventos del mapa
  const LocationSelector = () => {
    useMapEvents({
      click(e) {
        setMapLocation([e.latlng.lat, e.latlng.lng]);
        reverseGeocode(e.latlng.lat, e.latlng.lng); // Obtener dirección
      },
    });
    return null;
  };

  // Función para obtener la dirección a partir de coordenadas
  const reverseGeocode = async (latitude, longitude) => {
    try {
      setLoading(true);
      const response = await fetch(
        `https://us1.locationiq.com/v1/reverse.php?key=pk.b3cf34b8d099cc500fb41a11731e9f97&lat=${latitude}&lon=${longitude}&format=json`
      );
      const data = await response.json();
      if (response.ok && data.display_name) {
        setSelectedAddress(data.display_name);
        setError('');
      } else {
        setSelectedAddress('Error al obtener la dirección');
        setError('Error en la respuesta de la API');
      }
    } catch (err) {
      console.error('Error al obtener la dirección:', err);
      setSelectedAddress('Error al obtener la dirección');
      setError('Error al obtener la dirección');
    } finally {
      setLoading(false);
    }
  };

  // Función para guardar la dirección seleccionada
  const handleSaveAddress = async () => {
    // Verificar si la autenticación aún está en proceso
    if (authLoading) {
      setError('La autenticación aún está en proceso. Por favor, espera.');
      return;
    }

    // Verificar que el usuario esté autenticado y tenga un ID válido
    if (!user || !user.id) {
      setError('No se ha encontrado el ID del usuario. Por favor, inicia sesión nuevamente.');
      return;
    }

    // Validar que la descripción no esté vacía
    if (description.trim() === '') {
      setError('La descripción adicional no puede estar vacía.');
      return;
    }

    // Preparar los datos a enviar
    const payload = {
      address: selectedAddress,
      description,
      latitude: mapLocation[0],
      longitude: mapLocation[1],
      type,
    };

    console.log('Saving address with payload:', payload);

    try {
      setLoading(true);
      const token = localStorage.getItem('authToken'); // Obtener el token almacenado
      console.log('Using token:', token);

      const response = await fetch(`${backendUrl}/api/users/${user.id}/address`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Incluir el token si es necesario
        },
        body: JSON.stringify(payload),
      });

      const result = await response.json();
      console.log('Response from server:', result);

      if (response.ok) {
        alert(result.message);
        setDescription('');
        setError('');
        // Opcional: Puedes actualizar el estado de las direcciones si las tienes
      } else {
        setError(result.message || 'Error al guardar la dirección.');
      }
    } catch (err) {
      console.error('Error al guardar la dirección:', err);
      setError('Error al guardar la dirección.');
    } finally {
      setLoading(false);
    }
  };

  // Mostrar un mensaje de carga si la autenticación está en proceso
  if (authLoading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="configuraciones-container">
      <h2 className="text-center text-yellow-500 mb-4">Selecciona la ubicación en el mapa</h2>

      {/* Mapa para seleccionar la ubicación */}
      <div className="main-map-section mb-4">
        <MapContainer center={mapLocation} zoom={13} scrollWheelZoom={false} style={{ height: '300px', width: '100%' }}>
          <TileLayer 
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" 
            attribution="&copy; OpenStreetMap contributors" 
          />
          <Marker position={mapLocation} icon={customIcon}>
            <Popup>{selectedAddress}</Popup>
          </Marker>
          <LocationSelector />
        </MapContainer>
      </div>

      {/* Mostrar dirección seleccionada */}
      <div className="selected-address mb-4">
        <h3>Dirección Seleccionada:</h3>
        <p>{selectedAddress}</p>
      </div>

      {/* Formulario para tipo de dirección y descripción adicional */}
      <div className="form-group">
        <label htmlFor="type">Tipo de Dirección:</label>
        <select 
          id="type" 
          value={type} 
          onChange={(e) => setType(e.target.value)} 
          className="type-select"
        >
          <option value="primary">Principal</option>
          <option value="secondary">Secundaria</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="description">Descripción Adicional:</label>
        <input
          type="text"
          id="description"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Ej. Referencias, piso, etc."
          className="description-input"
        />
      </div>

      {/* Botón para guardar la dirección */}
      <div className="modal-actions mt-4">
        <button className="btn btn-success" onClick={handleSaveAddress} disabled={loading}>
          {loading ? 'Guardando...' : 'Guardar Dirección'}
        </button>
      </div>

      {/* Mostrar error si existe */}
      {error && <div className="error-message mt-4">{error}</div>}
    </div>
  );
};

export default Configuraciones;
