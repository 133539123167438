// src/pages/Payment.jsx

import React, { useState } from "react";
import api from "../services/api";
import { jsPDF } from "jspdf";

function Payment() {
  const storedBrandId = localStorage.getItem("brandId");
  const userRole = localStorage.getItem("role");
  const userToken = localStorage.getItem("token");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const paymentURL = "https://payp.page.link/ewkNP";

  const [registeredLocations, setRegisteredLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [qrCodeBase64, setQrCodeBase64] = useState("");
  const [uniqueUrl, setUniqueUrl] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isConfirmingPayment, setIsConfirmingPayment] = useState(false);

  const fetchLocations = async () => {
    if (!storedBrandId) return;
    try {
      const resp = await api.get(`/dashboard/brand/${storedBrandId}/locations`);
      if (resp.data && resp.data.locations) {
        setRegisteredLocations(resp.data.locations);
      } else {
        setRegisteredLocations([]);
      }
    } catch (error) {
      setErrorMessage("Error cargando sucursales: " + error);
    }
  };

  const handleOpenPaymentPage = () => {
    window.open(paymentURL, "_blank");
  };

  const handleConfirmPayment = async (desiredPlan = "premium") => {
    // desiredPlan = "premium" o "platinium"
    if (!userToken) {
      setErrorMessage("No estás autenticado. Por favor, inicia sesión.");
      return;
    }
    if (userRole !== "vendedor" && userRole !== "administrador") {
      setErrorMessage("No tienes permisos para actualizar el plan.");
      return;
    }

    setIsConfirmingPayment(true);
    try {
      if (storedBrandId) {
        await api.patch(`/clients/${storedBrandId}/update-plan`, {
          plan_type: desiredPlan,
        });
      }
      setShowConfirmation(true);
      await fetchLocations();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage("Token inválido o expirado. Inicia sesión nuevamente.");
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("brandId");
      } else {
        setErrorMessage("Ocurrió un error al actualizar el plan.");
      }
    } finally {
      setIsConfirmingPayment(false);
    }
  };

  const handleGenerateQr = async () => {
    setErrorMessage("");
    if (!selectedLocationId) {
      setErrorMessage("Selecciona una sucursal para generar el QR.");
      return;
    }
    try {
      const resp = await api.get(`/locations/${selectedLocationId}/generate-qr`);
      setQrCodeBase64(resp.data.qr_code_base64);
      setUniqueUrl(resp.data.unique_url);
    } catch (error) {
      setErrorMessage("Error al generar el código QR. Intenta nuevamente.");
    }
  };

  const handleDownloadSelect = (e) => {
    const format = e.target.value;
    if (!format) return;

    if (!qrCodeBase64) {
      setErrorMessage("No se ha generado aún el código QR.");
      e.target.value = "";
      return;
    }

    const base64Data = `data:image/png;base64,${qrCodeBase64}`;
    if (format === "jpg") {
      const link = document.createElement("a");
      link.href = base64Data;
      link.download = "qr_code.jpg";
      link.click();
    } else if (format === "pdf") {
      const doc = new jsPDF();
      doc.addImage(base64Data, "PNG", 10, 10, 50, 50);
      doc.save("qr_code.pdf");
    }
    e.target.value = "";
  };

  const closeModal = () => {
    setShowModal(false);
    setQrCodeBase64("");
    setUniqueUrl("");
    setSelectedLocationId("");
    setErrorMessage("");
  };

  const handleShowModal = async () => {
    await fetchLocations();
    setShowModal(true);
  };

  return (
    <div className="container mx-auto py-4 px-4">
      <h2 className="text-2xl font-bold mb-4">Pago en proceso</h2>

      {errorMessage && (
        <div className="mb-4 p-2 border border-red-300 bg-red-50 text-red-600 rounded">
          {errorMessage}
        </div>
      )}

      {!showConfirmation && (
        <>
          <p className="text-gray-600 mb-4">
            Para continuar con el pago, haz clic en{" "}
            <strong>"Ir a la pasarela de pago"</strong>. Luego de pagar, regresa
            y haz clic en <strong>"He finalizado mi pago"</strong>.
          </p>
          <button
            onClick={handleOpenPaymentPage}
            className="px-4 py-2 bg-[#FF3008] text-white rounded hover:bg-[#FF3008]/90 mb-4"
          >
            Ir a la pasarela de pago
          </button>
          <div className="mb-4">
            <p className="text-sm text-gray-700 mb-2">
              Elige a qué plan quieres pasar (ejemplo):
            </p>
            <button
              onClick={() => handleConfirmPayment("premium")}
              className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 mr-4"
              disabled={isConfirmingPayment}
            >
              {isConfirmingPayment ? "Actualizando plan..." : "Plan Premium"}
            </button>
            <button
              onClick={() => handleConfirmPayment("platinium")}
              className="px-4 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
              disabled={isConfirmingPayment}
            >
              {isConfirmingPayment
                ? "Actualizando plan..."
                : "Plan Platinium"}
            </button>
          </div>
        </>
      )}

      {showConfirmation && (
        <div className="text-center">
          <h3 className="text-lg font-semibold text-green-600 mb-4">
            ¡Pago Completado!
          </h3>
          <p className="text-gray-700 mb-4">
            Tu plan se ha actualizado correctamente.
          </p>
          <p className="text-gray-600 mb-4">
            Ahora podrás disfrutar de las funcionalidades del plan adquirido.
          </p>
          <button
            onClick={handleShowModal}
            className="px-4 py-2 bg-[#FF3008] text-white rounded hover:bg-[#FF3008]/90"
          >
            Ver Sucursales / Generar QR
          </button>
        </div>
      )}

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-md p-6 max-w-sm w-full relative">
            <h2 className="text-xl font-bold mb-4">Generar QR de Sucursales</h2>

            {errorMessage && (
              <div className="mb-4 p-2 border border-red-300 bg-red-50 text-red-600 rounded">
                {errorMessage}
              </div>
            )}

            {registeredLocations.length === 0 ? (
              <p className="text-sm text-gray-500">
                No hay sucursales registradas o hubo un problema al cargarlas.
              </p>
            ) : (
              <>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Selecciona la sucursal
                </label>
                <select
                  value={selectedLocationId}
                  onChange={(e) => setSelectedLocationId(e.target.value)}
                  className="mb-4 block w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">-- Seleccionar --</option>
                  {registeredLocations.map((loc) => (
                    <option key={loc.id} value={loc.id}>
                      {loc.name} (ID: {loc.id})
                    </option>
                  ))}
                </select>

                <button
                  onClick={handleGenerateQr}
                  className="w-full py-2 bg-[#FF3008] text-white rounded hover:bg-[#FF3008]/90 mb-4"
                >
                  Generar QR
                </button>

                {qrCodeBase64 && (
                  <div className="mb-4">
                    <div className="flex justify-center mb-4">
                      <img
                        src={`data:image/png;base64,${qrCodeBase64}`}
                        alt="QR Code"
                        className="w-40 h-40"
                      />
                    </div>
                    {uniqueUrl && (
                      <p className="text-sm text-gray-600 mb-2">
                        <strong>URL: </strong>
                        <a
                          href={uniqueUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-600 underline"
                        >
                          {uniqueUrl}
                        </a>
                      </p>
                    )}
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Descargar como
                    </label>
                    <select
                      onChange={handleDownloadSelect}
                      className="block w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">-- Selecciona formato --</option>
                      <option value="jpg">JPG</option>
                      <option value="pdf">PDF</option>
                    </select>
                  </div>
                )}
              </>
            )}
            <button
              onClick={closeModal}
              className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Payment;
