// src/components/LoadingSpinner.js
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const LoadingSpinner = () => (
  <div className="d-flex justify-content-center my-3">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

export default LoadingSpinner;