// src/App.js

import React, { useState } from "react";
import { Routes, Route, Link, useNavigate, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import RegisterClient from "./pages/RegisterClient";
import Locations from "./pages/Locations";
import Questions from "./pages/Questions";
import Dashboard from "./pages/Dashboard";
import PublicSurvey from "./pages/PublicSurvey";
import ClientSelection from "./pages/ClientSelection";
import Payment from "./pages/Payment";
import ClientsList from "./pages/ClientsList";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
// Nueva importación
import Account from "./pages/Account";

import logo from "./images/logo.png";
import "./styles.css";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = localStorage.getItem("token");
  const userRole = localStorage.getItem("role");

  // Detecta si la ruta actual es de encuesta pública
  const isPublicSurveyRoute = /^\/[^/]+\/[^/]+$/.test(location.pathname);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("brandId");
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-h-screen">
      {!isPublicSurveyRoute && (
        <header className="bg-[#FF3008] text-white px-4 py-3 flex justify-between items-center">
          <div className="flex items-center space-x-2 cursor-pointer">
            <img src={logo} alt="Logo" className="w-8 h-8" />
            <span className="font-bold">Hidden Shopper</span>
          </div>
          <div className="relative">
            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="text-white focus:outline-none"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>

            {menuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-gray-800 text-white rounded-md shadow-lg py-2 z-50">
                <Link
                  to="/"
                  className="block px-4 py-2 hover:bg-gray-700"
                  onClick={() => setMenuOpen(false)}
                >
                  Inicio
                </Link>
                <Link
                  to="/register-client"
                  className="block px-4 py-2 hover:bg-gray-700"
                  onClick={() => setMenuOpen(false)}
                >
                  Registrar Marca
                </Link>
                {!isLoggedIn && (
                  <Link
                    to="/login"
                    className="block px-4 py-2 hover:bg-gray-700"
                    onClick={() => setMenuOpen(false)}
                  >
                    Login
                  </Link>
                )}
                {isLoggedIn && (
                  <>
                    <Link
                      to="/locations"
                      className="block px-4 py-2 hover:bg-gray-700"
                      onClick={() => setMenuOpen(false)}
                    >
                      Ubicaciones
                    </Link>
                    <Link
                      to="/questions"
                      className="block px-4 py-2 hover:bg-gray-700"
                      onClick={() => setMenuOpen(false)}
                    >
                      Preguntas
                    </Link>
                    <Link
                      to="/dashboard"
                      className="block px-4 py-2 hover:bg-gray-700"
                      onClick={() => setMenuOpen(false)}
                    >
                      Dashboard
                    </Link>
                    <Link
                      to="/clients-list"
                      className="block px-4 py-2 hover:bg-gray-700"
                      onClick={() => setMenuOpen(false)}
                    >
                      Clientes
                    </Link>
                    {/* Nuevo enlace Cuenta */}
                    <Link
                      to="/account"
                      className="block px-4 py-2 hover:bg-gray-700"
                      onClick={() => setMenuOpen(false)}
                    >
                      Cuenta
                    </Link>
                    <button
                      onClick={() => {
                        handleLogout();
                        setMenuOpen(false);
                      }}
                      className="w-full text-left px-4 py-2 hover:bg-gray-700"
                    >
                      Cerrar Sesión
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </header>
      )}

      <Routes>
        <Route path="/select-client" element={<ClientSelection />} />
        <Route path="/:brandSlug/:locationSlug" element={<PublicSurvey />} />
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register-client" element={<RegisterClient />} />
        <Route path="/locations" element={<Locations />} />
        <Route path="/questions" element={<Questions />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/clients-list" element={<ClientsList />} />

        {/* RUTAS NUEVAS PARA RESET DE CONTRASEÑA */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />

        {/* Ruta nueva para ver la cuenta */}
        <Route path="/account" element={<Account />} />
      </Routes>
    </div>
  );
}

export default App;
