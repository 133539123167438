import React from 'react';
import { MapContainer, TileLayer, Marker, Polygon, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

const MapComponent = ({ puntos, onClick, drawLines, color = 'blue' }) => {
    const initialCoordinates = [-0.199, -78.479]; // Coordenadas iniciales de ejemplo

    // Función para manejar el clic en el mapa
    const MapClickHandler = () => {
        useMapEvents({
            click(event) {
                if (onClick) {
                    const { lat, lng } = event.latlng;
                    console.log("Mapa clickeado en:", { lat, lng });
                    onClick({ lat, lng });
                }
            },
        });
        return null; // Este componente no necesita renderizado, solo manejar el evento
    };

    return (
        <MapContainer 
            center={initialCoordinates} 
            zoom={13} 
            style={{ height: "400px", width: "100%" }}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapClickHandler /> {/* Manejador del evento de clic */}
            {puntos && puntos.length > 0 && puntos.map((punto, index) => ( // Verificación para evitar errores con undefined
                <Marker key={index} position={[punto.lat, punto.lng]} />
            ))}
            {drawLines && puntos && puntos.length > 0 && ( // Verificación para evitar errores con undefined
                <Polygon positions={puntos.map(p => [p.lat, p.lng])} color={color} />
            )}
        </MapContainer>
    );
};

export default MapComponent;
